import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { breakPointsBannerSlider } from "./BreakPointsCarousel";
import { ProductCarouselNavigationItems } from "./ProductCarouselNavigationItem";
import {
  Image,
  ProductDetailsDataInterface,
} from "../../interfaces/ProductDetailsDataInterface";
import { ProductCarouselItem } from "./ProductCarouselItem";
import { useAppDispatch } from "../../../app/hooks";
import {
  setActive,
  setLightboxSlices,
} from "../../../modals/productGalleryModal/productGalleryModalSlice";

interface Props {
  data?: ProductDetailsDataInterface;
}

export const ProductCarousel = (props: Props) => {
  const { data } = props;
  const dispatch = useAppDispatch();

  return (
    <div className="c-product-carousel">
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={breakPointsBannerSlider}
        arrows={false}
        customButtonGroup={
          <ProductCarouselNavigationItems next={() => {}} previous={() => {}} />
        }
        itemClass="carousel-item-padding"
      >
        {data
          ? data.productImages.map((slice: Image, index: number) => (
              <ProductCarouselItem
                key={index}
                isVideo={slice.type === "video"}
                imageUrl={slice.converted.HD ?? slice.url}
                handleOnClick={() => {
                  dispatch(setLightboxSlices(data.productImages));
                  dispatch(setActive(true));
                }}
              />
            ))
          : Array.from(Array(4)).map((_, index) => (
              <div
                key={index}
                className="c-product-carousel__item c-product-carousel__item__banner skeleton skeleton-carousel"
              >
                <div className="c-product-carousel__item__expand-icon">
                  <img src={"/images/fullscreen.svg"} alt="" />
                </div>
              </div>
            ))}
      </Carousel>
    </div>
  );
};
