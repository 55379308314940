import { createSlice } from "@reduxjs/toolkit";

interface DashboardPageInterface {
  loading: boolean;
}

const initialState: DashboardPageInterface = {
  loading: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
});

export const dashboardReducer = dashboardSlice.reducer;
