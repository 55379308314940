import { Translate } from "../../utils/Translate";
import { StringTranslation } from "../../utils/StringTranslation";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { LanguageInterface } from "../../interfaces/LanguageInterface";
import { TranslationsInterface } from "../../interfaces/TranslationsInterface";

interface Props {
  activeLanguage: LanguageInterface | null;
  id: number;
  title: string;
  image: string;
}

export const ProductOlderModelsItem = (props: Props) => {
  const { data: translations } = useGetTranslationsQuery();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };

  return (
    <div className="c-older-models__item">
      <div className="c-older-models__item__top">
        <div className="carousel-product-image">
          <img src={props.image} alt="thumbnail" />
        </div>
      </div>
      <div className="c-older-models__item__bottom">
        <div className="footer-top">
          <span>
            {Translate(
              props.title as unknown as TranslationsInterface,
              props.activeLanguage,
            )}
          </span>
        </div>
        <div className="button-container">
          <div className="c-btn c-btn--primary btn-view-product">
            <a href={`/product-range/${props.id}`}>
              {StringTranslation("view-product", translations, activeLanguage)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
