import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteEnum } from "../common/enums/RouteEnum";
import { useAppSelector } from "./hooks";
import { Wrapper } from "../wrappers/app/Wrapper";
import ProductRangePageContainer from "../pages/productRangePage/ProductRangePageContainer";
import ProductDetailsPageContainer from "../pages/productDetail/ProductDetailsPageContainer";
import CollectionsPageContainer from "../pages/collectionsPage/CollectionsPageContainer";
import { DeleteCollectionModalContainer } from "../modals/deleteCollectionModal/DeleteCollectionModalContainer";
import { DeleteCollectionProductModalContainer } from "../modals/deleteCollectionProductModal/DeleteCollectionProductModalContainer";
import { EditCollectionModalContainer } from "../modals/editCollectionModal/EditCollectionModalContainer";
import { AddCollectionProductsModalContainer } from "../modals/addCollectionProductsModal/AddCollectionProductsModalContainer";
import PriceBookPageContainer from "../pages/priceBookPage/PriceBookPageContainer";
import { AddCollectionModalContainer } from "../modals/addCollectionModal/AddCollectionModalContainer";
import { ProductGalleryModal } from "../modals/productGalleryModal/ProductGalleryModal";
import SearchPageContainer from "../pages/searchPage/SearchPageContainer";
import { useGetTranslationsQuery, useGetUserInfoQuery } from "./rootReducer";
import { useContext } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import AccountPageContainer from "../pages/accountPage/AccountPageContainer";

const App = () => {
  const {
    deleteCollectionModal,
    deleteCollectionProductModal,
    editCollectionModal,
    addCollectionProductsModal,
    addCollectionModal,
  } = useAppSelector((state) => state);

  const { login } = useContext<IAuthContext>(AuthContext);

  const { data: translationsData, isError: translationsError } =
    useGetTranslationsQuery();
  const { data: userInfoData, isError: userInfoError } = useGetUserInfoQuery();

  if (
    (!translationsData && translationsError) ||
    (!userInfoData && userInfoError)
  ) {
    localStorage.clear();
    login();
  }
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route
            path={RouteEnum.PRODUCT_RANGE}
            element={<ProductRangePageContainer />}
          />
          <Route
            path={RouteEnum.COLLECTIONS}
            element={<CollectionsPageContainer />}
          />
          <Route
            path={RouteEnum.PRODUCT_RANGE + "/:productId"}
            element={<ProductDetailsPageContainer />}
          />
          <Route path={RouteEnum.ACCOUNT} element={<AccountPageContainer />} />
          <Route
            path={RouteEnum.PRICEBOOK}
            element={<PriceBookPageContainer />}
          />
          <Route path={RouteEnum.SEARCH} element={<SearchPageContainer />} />
        </Routes>

        <ProductGalleryModal />

        {deleteCollectionModal.collectionId && (
          <DeleteCollectionModalContainer />
        )}
        {deleteCollectionProductModal.collectionId && (
          <DeleteCollectionProductModalContainer />
        )}
        {editCollectionModal.collectionId && <EditCollectionModalContainer />}
        {addCollectionProductsModal.collectionId && (
          <AddCollectionProductsModalContainer />
        )}
        {addCollectionModal.selectedProductIds && (
          <AddCollectionModalContainer />
        )}
      </Wrapper>
    </BrowserRouter>
  );
};

export default App;
