import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../common/enums/ApiEnum";
import { userInterface } from "../common/interfaces/UserInterface";
import { apiBaseQuery } from "../common/utils/apiBaseQuery";
import { TranslationsInterface } from "../common/interfaces/TranslationsInterface";

export interface RootInterface {
  menuToggle: boolean;
  authToken?: string;
  showIntroduction: boolean;
}

const initialState: RootInterface = {
  menuToggle: false,
  showIntroduction: !localStorage.getItem("introductionSlide"),
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    menuToggle: (state) => {
      state.menuToggle = !state.menuToggle;

      if (state.menuToggle && document.body.clientWidth <= 1100) {
        document.body.style.overflow = "hidden";
      }
      if (!state.menuToggle && document.body.clientWidth <= 1100) {
        document.body.style.overflow = "auto";
      }
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setShowIntroduction: (state, action: PayloadAction<boolean>) => {
      state.showIntroduction = action.payload;
    },
  },
});

export const rootApiSlice = createApi({
  reducerPath: "rootApi",
  baseQuery: apiBaseQuery(),
  tagTypes: [],
  endpoints: (builder) => ({
    getUserInfo: builder.query<userInterface, void>({
      query: () => ({
        url: ApiEnum.USER,
      }),
    }),
    setUserLanguage: builder.mutation<
      userInterface,
      { languageId: number; countryId: number }
    >({
      query: (params) => ({
        url: ApiEnum.USER + "/localisation",
        method: "PATCH",
        body: {
          activeLanguageId: params.languageId,
          activeCountryId: params.countryId,
        },
      }),
    }),
    setUserCountry: builder.mutation<userInterface, number>({
      query: (countryId) => ({
        url: ApiEnum.USER + "/localisation",
        method: "PATCH",
        body: { activeCountryId: countryId },
      }),
    }),
    getTranslations: builder.query<TranslationsInterface, void>({
      query: () => ({
        url: ApiEnum.TRANSLATIONS + "/b2b_dealer",
      }),
    }),
  }),
});

export const { menuToggle, setAuthToken, setShowIntroduction } =
  rootSlice.actions;

export default rootSlice.reducer;

export const {
  useGetUserInfoQuery,
  useSetUserLanguageMutation,
  useSetUserCountryMutation,
  useGetTranslationsQuery,
  useLazyGetUserInfoQuery,
} = rootApiSlice;
