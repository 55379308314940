import { Link } from "react-router-dom";
import { RouteEnum } from "../../enums/RouteEnum";
import { dayPeriod } from "../../utils/dayPeriod";
import { useAppDispatch } from "../../../app/hooks";
import { menuToggle, useGetUserInfoQuery } from "../../../app/rootReducer";
import { getDate } from "../../utils/getDate";

export const MobileHeader = () => {
  const dispatch = useAppDispatch();

  const { data } = useGetUserInfoQuery();
  const { firstName, insertion, lastName } = data || {
    firstName: "",
    insertion: "",
    lastName: "",
  };

  const currentDate = getDate();

  return (
    <div className="l-app__mobile-header">
      <div className="l-app__mobile-header__top">
        <div className="l-app__mobile-header__buttons">
          <div
            className="l-app__mobile-header__buttons__menu"
            onClick={() => dispatch(menuToggle())}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <Link to={RouteEnum.PRODUCT_RANGE} className="l-app__mobile-logo">
          <img src="/images/Bravilor-logo.svg" alt="logo" />
        </Link>
        <div className="l-app__mobile-header__buttons">
          <Link to={RouteEnum.ACCOUNT}>
            <img src="/images/account.svg" alt="account" />
          </Link>
        </div>
      </div>
      <div className="l-app__mobile-header__bottom">
        {!!firstName && !!lastName && (
          <span className="l-app__mobile-header__name">
            {dayPeriod()} {firstName} {insertion} {lastName}
          </span>
        )}

        {!!currentDate && (
          <span className="l-app__mobile-header__date">{currentDate}</span>
        )}
      </div>
    </div>
  );
};
