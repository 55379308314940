import React, { useState } from "react";
import { Navigation } from "../../common/components/general/Navigation";
import { Link, useNavigate } from "react-router-dom";
import { RouteEnum } from "../../common/enums/RouteEnum";
import { Tooltip } from "../../common/components/general/Tooltip";
import { LanguagePicker } from "../../common/components/general/LanguagePicker";
import { MobileHeader } from "../../common/components/general/MobileHeader";
import { useAppLocation, useAppSelector } from "../../app/hooks";
import {
  menuToggle,
  setShowIntroduction,
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";
import { useDispatch } from "react-redux";
import { Introduction } from "../../common/components/general/Introduction";
import { CountryPicker } from "../../common/components/general/CountryPicker";
import { LogOutButton } from "../../common/components/general/LogOutButton";
import { MenuAccountInfo } from "../../common/components/general/MenuAccountInfo";

interface Props {
  children?: React.ReactNode;
}

export const Wrapper = (props: Props) => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [countryPickerState, setCountryPickerState] = useState<boolean>(false);
  const [languagePickerState, setLanguagePickerState] =
    useState<boolean>(false);

  const userData = useGetUserInfoQuery();

  const { activeLanguage, countries } = userData?.data || {
    activeLanguage: null,
    countries: [],
  };
  const { data: translations } = useGetTranslationsQuery();

  const { menuToggle: menuToggleCheck } = useAppSelector((state) => state.root);

  const roles = userData?.data?.roles || [];

  if (location.pathname === "/") {
    navigate(RouteEnum.PRODUCT_RANGE);
  }

  const handleTooltipClick = () => {
    localStorage.setItem("introductionSlide", "true");
    dispatch(setShowIntroduction(true));
  };

  return (
    <section
      className={
        "l-app" +
        (location.pathname === "/dashboard" ? " is-active " : "") +
        (menuToggleCheck ? " menu-open" : "")
      }
    >
      <Introduction />
      <MobileHeader />
      <div className="l-app__menu-overlay"></div>
      <aside className="l-app__menu">
        <div className="l-app__menu__content">
          <Link to={RouteEnum.PRODUCT_RANGE} className="l-app__logo">
            <img src="/images/Bravilor-logo.svg" alt="logo" />
          </Link>
          <div className="l-app__menu__search-bar">
            <input
              type="text"
              onClick={() => {
                navigate(RouteEnum.SEARCH);
                dispatch(menuToggle());
              }}
              placeholder={StringTranslation(
                "search-for-machines-accessories-or-parts",
                translations,
                activeLanguage,
              )}
            />
            <div className="l-app__menu__search-bar__icon">
              <img src="/images/search.svg" alt="search" />
            </div>
          </div>
          <Navigation />
        </div>
        <div className="l-app__menu__content">
          <div className="l-app__menu__account">
            <MenuAccountInfo />
            <LogOutButton />
          </div>
          <div className="l-app__menu__translate">
            {!!roles && roles.some((role: string) => (role === "admin" || role === 'regional admin')) && (
              <a
                className="l-app__menu__account__name"
                href={process.env.REACT_APP_ADMIN_URL}
              >
                <u>Admin</u>
              </a>
            )}
          </div>
          {countries && countries.length > 1 && (
            <div className="l-app__menu__translate">
              <CountryPicker
                active={countryPickerState}
                setActive={setCountryPickerState}
                setLanguageActive={setLanguagePickerState}
              />
            </div>
          )}
          <div className="l-app__menu__translate">
            <LanguagePicker
              active={languagePickerState}
              setActive={setLanguagePickerState}
              setCountryActive={setCountryPickerState}
            />
            <Tooltip text={"Introduction"} onClick={handleTooltipClick} />
          </div>
        </div>
      </aside>
      <main className="l-app__content">{props.children}</main>
    </section>
  );
};
