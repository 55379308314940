import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setGridLayout,
  setProductType,
  useGetProductRangeQuery,
} from "../../../pages/productRangePage/ProductRangeSlice";
import { CategoryItem } from "./CategoryItem";
import { ProductSelectedItem } from "./ProductSelectedItem";
import { Key, useState } from "react";
import { ProductTypeEnum } from "../../enums/ProductTypeEnum";
import { ProductInterface } from "../../interfaces/ProductInterface";
import { CategoryListInterface } from "../../interfaces/CategoryListInterface";
// import {SelectedProductsExportBar} from "./SelectedProductsExportBar";
import { setAddCollectionModal } from "../../../modals/addCollectionModal/AddCollectionModalSlice";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";
// import {ExportTypeInterface} from "../../interfaces/ExportTypeInterface";

export const Products = () => {
  const dispatch = useAppDispatch();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const [selectedActive, setSelectedActive] = useState(false);

  const { selectedProducts, gridLayout, productType, selectedFilters } =
    useAppSelector((state) => state.productRange);

  const { data, isFetching } = useGetProductRangeQuery({
    type: productType,
    filters: selectedFilters,
  });
  const categories = data?.categories;

  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

  // const exportTypes = [
  //     {
  //         id: 1,
  //         label: 'Pricelist',
  //         type: 'csv_pricelist',
  //         fileType: 'CSV',
  //         color: 'green',
  //     },
  //     {
  //         id: 2,
  //         label: 'Pricelist',
  //         type: 'pdf_pricelist',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  //     {
  //         id: 3,
  //         label: 'Catalog',
  //         type: 'pdf_catalog',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  //     {
  //         id: 4,
  //         label: 'Productsheet',
  //         type: 'pdf_productsheet',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  // ] as ExportTypeInterface[]

  return (
    <div className="c-products">
      <div className="c-products__nav">
        <div className="c-products-nav">
          <div
            className={
              "c-products-nav__item " +
              (productType === ProductTypeEnum.products ? "is-active" : "")
            }
            onClick={() => dispatch(setProductType(ProductTypeEnum.products))}
          >
            {translationsLoaded ? (
              <span>
                {StringTranslation("machines", translations, activeLanguage)}
              </span>
            ) : (
              <div className="skeleton skeleton-name"></div>
            )}
          </div>
          <div
            className={
              "c-products-nav__item " +
              (productType === ProductTypeEnum.accessories ? "is-active" : "")
            }
            onClick={() =>
              dispatch(setProductType(ProductTypeEnum.accessories))
            }
          >
            {translationsLoaded ? (
              <span>
                {StringTranslation("accessories", translations, activeLanguage)}
              </span>
            ) : (
              <div className="skeleton skeleton-name"></div>
            )}
          </div>
        </div>
        <div className="c-products-layout-switch">
          <div
            className={
              "c-products-layout-switch__button " +
              (!gridLayout ? "is-active" : "")
            }
            onClick={() => dispatch(setGridLayout(false))}
          >
            {gridLayout ? (
              <img src="/images/list-view-black.svg" alt="list" />
            ) : (
              <img src="/images/list-view-white.svg" alt="list" />
            )}

            {translationsLoaded && (
              <span>
                {StringTranslation("list", translations, activeLanguage)}
              </span>
            )}
          </div>
          <div
            className={
              "c-products-layout-switch__button " +
              (gridLayout ? "is-active" : "")
            }
            onClick={() => dispatch(setGridLayout(true))}
          >
            {gridLayout ? (
              <img src="/images/grid-view-white.svg" alt="grid" />
            ) : (
              <img src="/images/grid-view-black.svg" alt="grid" />
            )}

            {translationsLoaded && (
              <span>
                {StringTranslation("grid", translations, activeLanguage)}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="c-products__selection">
        <div
          className={
            "c-products-selected__overlay " +
            (selectedActive ? "is-active" : "")
          }
        ></div>
        <div
          className={
            "c-products-selected " + (selectedActive ? "is-active" : "")
          }
        >
          <div
            className="c-products-selected__header"
            onClick={() => setSelectedActive(!selectedActive)}
          >
            <div className="c-products-selected__header__left">
              {translationsLoaded ? (
                <>
                  <span className="c-products-selected__header__bold">
                    {StringTranslation(
                      "selection",
                      translations,
                      activeLanguage,
                    )}
                    ({selectedProducts.length}/50)
                  </span>
                  <span>
                    {StringTranslation(
                      "select-products-to-save-to-a-collection-or-to-create-an-export",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                </>
              ) : (
                <div className="skeleton skeleton-text"></div>
              )}
            </div>
            <div className="c-products-selected__header__right">
              <img src="/images/arrow-black.svg" alt="arrow-down" />
            </div>
          </div>

          {!!selectedProducts && selectedProducts.length > 0 && (
            <div className="c-products-selected__container">
              <div className="c-products-selected__items">
                {selectedProducts.map(
                  (product: ProductInterface, index: number) => (
                    <ProductSelectedItem key={index} product={product} />
                  ),
                )}
              </div>

              <div className="c-products-selected__footer">
                <div className="c-products-selected__footer__left">
                  <button
                    className="c-btn c-btn--primary"
                    onClick={() => {
                      dispatch(
                        setAddCollectionModal({
                          showPopup: true,
                          selectedProductIds: selectedProducts.map(
                            (product: ProductInterface) => product.id,
                          ),
                        }),
                      );
                    }}
                  >
                    <img src="/images/list-view-white.svg" alt="list" />
                    <span>
                      {StringTranslation(
                        "add-to-collection",
                        translations,
                        activeLanguage,
                      )}
                    </span>
                  </button>
                </div>

                {/*<div className="c-products-selected__footer__right">*/}
                {/*    <SelectedProductsExportBar*/}
                {/*        exportTypes={exportTypes}*/}
                {/*        translations={translations}*/}
                {/*        activeLanguage={activeLanguage}*/}
                {/*    />*/}
                {/*</div>*/}
              </div>
            </div>
          )}
        </div>

        {!isFetching && !!categories ? (
          <div className="c-products-list">
            {categories.map(
              (
                category: CategoryListInterface,
                index: Key | null | undefined,
              ) => (
                <CategoryItem
                  key={index}
                  category={category}
                  standardActive={categories.length === 1}
                />
              ),
            )}
          </div>
        ) : (
          <CategoryItemSkeleton />
        )}
      </div>
    </div>
  );
};

const CategoryItemSkeleton = () => {
  return (
    <>
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <div className={"c-products-list__item "} key={index}>
            <div className="c-products-list__item__header">
              <span className="skeleton skeleton-title"></span>
              <div className="c-products-list__item__header__icon">
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
