import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../app/store";

export function apiBaseQuery() {
  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_URL,
    prepareHeaders: (headers, { getState }) => {
      const { root } = getState() as RootState;
      const { authToken } = root;
      if (authToken) {
        headers.set("Authorization", `Bearer ${authToken}`);
      }
      return headers;
    },
  });
}
