import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Searchbar } from "../../common/components/general/Searchbar";

//import {ExportBar} from "../../common/components/productRange/ExportBar";
import {
  ProductFilter,
  ProductFilterSkeleton,
} from "../../common/components/productRange/ProductFilter";

import { Products } from "../../common/components/productRange/Products";
import {
  resetFilter,
  setGridLayout,
  setMobileFiltersActive,
  useGetProductRangeFilterQuery,
} from "./ProductRangeSlice";
import { SvgReset } from "../../common/components/svg/SvgReset";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";
//import {ExportTypeInterface} from "../../common/interfaces/ExportTypeInterface";
import { ProductTypeEnum } from "../../common/enums/ProductTypeEnum";

const ProductRangePageContainer = () => {
  const dispatch = useAppDispatch();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations, isLoading: isLoadingTranslations } =
    useGetTranslationsQuery();

  const { gridLayout, selectedFilters, productType, mobileFiltersActive } =
    useAppSelector((state) => state.productRange);

  const { data: filters, isLoading: isLoadingFilters } =
    useGetProductRangeFilterQuery();

  // const exportTypes = [
  //     {
  //         id: 1,
  //         label: 'Pricelist',
  //         type: 'csv_pricelist',
  //         fileType: 'CSV',
  //         color: 'green',
  //     },
  //     {
  //         id: 2,
  //         label: 'Pricelist',
  //         type: 'pdf_pricelist',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  //     {
  //         id: 3,
  //         label: 'Catalog',
  //         type: 'pdf_catalog',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  //     {
  //         id: 4,
  //         label: 'Productsheet',
  //         type: 'pdf_productsheet',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  // ] as ExportTypeInterface[]

  return (
    <>
      <div className="p-product-range">
        <div className="p-product-range__header">
          <div className="c-breadcrumbs">
            {isLoadingTranslations ? (
              <>
                <span className="skeleton skeleton-name"></span>
                <div className="c-breadcrumbs__arrow">
                  <img src="/images/arrow-black.svg" alt="arrow" />
                </div>
                <span className="skeleton skeleton-name"></span>
              </>
            ) : (
              <>
                <span>
                  {StringTranslation(
                    "product-range",
                    translations,
                    activeLanguage,
                  )}
                </span>
                <div className="c-breadcrumbs__arrow">
                  <img src="/images/arrow-black.svg" alt="arrow" />
                </div>
                {productType === ProductTypeEnum.products && (
                  <span>
                    {StringTranslation(
                      "machines",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                )}
                {productType === ProductTypeEnum.accessories && (
                  <span>
                    {StringTranslation(
                      "accessories",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                )}
              </>
            )}
          </div>
          <Searchbar activeLanguage={activeLanguage} />
        </div>

        <div className="p-product-range__content">
          {isLoadingFilters ? (
            <div className="c-product-filters">
              <div className="c-product-filters__container">
                {Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <ProductFilterSkeleton key={index} />
                  ))}
              </div>
            </div>
          ) : (
            <div
              className={
                "c-product-filters " + (mobileFiltersActive ? "is-active" : "")
              }
            >
              <div className="c-product-filters__container">
                {!!filters &&
                  filters.map((filter, index) => (
                    <ProductFilter key={index} filter={filter} />
                  ))}
                <div className="c-product-filters__btn-reset__container">
                  <button
                    className={`c-product-filters__btn-reset ${selectedFilters.length > 0 && selectedFilters.every((value: object | null) => value != null) && "c-product-filters__btn-reset--active"}`}
                    onClick={() => dispatch(resetFilter())}
                  >
                    <SvgReset
                      color={
                        selectedFilters.length > 0 &&
                        selectedFilters.every(
                          (value: object | null) => value != null,
                        )
                          ? "white"
                          : "black"
                      }
                    />
                    <span>
                      {StringTranslation(
                        "reset-filters",
                        translations,
                        activeLanguage,
                      )}
                    </span>
                  </button>
                </div>
              </div>
              <div
                className="c-product-filters__mobile-footer"
                onClick={() =>
                  dispatch(setMobileFiltersActive(!mobileFiltersActive))
                }
              >
                <img src="/images/filter.svg" alt="" />
                <span>
                  {StringTranslation(
                    "filter-results",
                    translations,
                    activeLanguage,
                  )}
                </span>
              </div>
            </div>
          )}

          <div className="p-product-range__content__range">
            <div className="p-product-range__content__range__mobile-nav">
              <div
                className="c-product-filter-menu-btn"
                onClick={() =>
                  dispatch(setMobileFiltersActive(!mobileFiltersActive))
                }
              >
                <img src="/images/filter.svg" alt="" />
                {isLoadingTranslations ? (
                  <span className="skeleton skeleton-button"></span>
                ) : (
                  <span>
                    {StringTranslation(
                      "filter-results",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                )}
              </div>
              <div className="c-products-layout-switch">
                <div
                  className={
                    "c-products-layout-switch__button " +
                    (!gridLayout ? "is-active" : "")
                  }
                  onClick={() => dispatch(setGridLayout(false))}
                >
                  {gridLayout && (
                    <img src="/images/list-view-black.svg" alt="list" />
                  )}
                  {!gridLayout && (
                    <img src="/images/list-view-white.svg" alt="list" />
                  )}

                  {!isLoadingTranslations ? (
                    <span>
                      {StringTranslation("list", translations, activeLanguage)}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    "c-products-layout-switch__button " +
                    (gridLayout ? "is-active" : "")
                  }
                  onClick={() => dispatch(setGridLayout(true))}
                >
                  {!gridLayout && (
                    <img src="/images/grid-view-black.svg" alt="grid" />
                  )}
                  {gridLayout && (
                    <img src="/images/grid-view-white.svg" alt="grid" />
                  )}

                  {!isLoadingTranslations ? (
                    <span>
                      {StringTranslation("grid", translations, activeLanguage)}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <Products />
            {/*<ExportBar*/}
            {/*    exportTypes={exportTypes}*/}
            {/*    activeLanguage={activeLanguage}*/}
            {/*    productType={productType}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRangePageContainer;
