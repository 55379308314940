import { SvgPlay } from "../svg/SvgPlay";

interface Props {
  imageUrl: string;
  handleOnClick: () => void;
  isVideo?: boolean;
}

export const ProductCarouselItem = (props: Props) => {
  const { isVideo, imageUrl, handleOnClick } = props;

  return (
    <div
      className="c-product-carousel__item c-product-carousel__item__banner"
      onClick={handleOnClick}
    >
      {!isVideo ? (
        <>
          <div className="c-product-carousel__item__expand-icon">
            <img src={"/images/fullscreen.svg"} alt="" />
          </div>
          <img src={imageUrl} alt="#" />
        </>
      ) : (
        <>
          <div className="c-product-carousel__item__play-icon">
            <SvgPlay color={"#FFF"} />
          </div>

          <div
            className="video-banner-slide"
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundPosition: "center",
              backgroundAttachment: "cover",
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
            }}
          />
        </>
      )}
    </div>
  );
};
