import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";

export const LogOutButton = () => {
  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return isTranslationsLoading ? (
    <span className="skeleton skeleton-button"></span>
  ) : (
    <span className="log-out" onClick={handleLogout}>
      {StringTranslation("log-out", translations, activeLanguage)}
    </span>
  );
};
