import { ProductDetailsDataInterface } from "../../interfaces/ProductDetailsDataInterface";
import { LanguageInterface } from "../../interfaces/LanguageInterface";
import { useGetTranslationsQuery } from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";
import { Translate } from "../../utils/Translate";
import { convertUnitToAbbreviation } from "../../utils/convertUnitToAbbreviation";
import { MetricValue } from "../../interfaces/MetricValueInterface";
import React from "react";
import { TranslationsInterface } from "../../interfaces/TranslationsInterface";

interface Props {
  data?: ProductDetailsDataInterface;
  activeLanguage: LanguageInterface | null;
}

interface ProductAttribute {
  type: string;
  label: string;
  value: { [key: string]: string | number | boolean | MetricValue };
}

export const ProductSpecifications = (prop: Props) => {
  const { data, activeLanguage } = prop;
  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

  const attributes: React.JSX.Element[] = [];

  if (!!data && !!activeLanguage) {
    data.productAttributes?.forEach((item, index) => {
      const attributeElement = RenderAttributes(
        item as unknown as ProductAttribute,
        data.identifier,
        translations as TranslationsInterface,
        index,
        activeLanguage,
      );
      if (attributeElement) {
        attributes.push(attributeElement);
      }
    });
  }

  if (!attributes) return null;

  return (
    <div className="c-product-specs">
      <div className="header-title">
        {translationsLoaded ? (
          <h2>
            {StringTranslation("specifications", translations, activeLanguage)}
          </h2>
        ) : (
          <h2 className="skeleton skeleton-title-large" />
        )}
      </div>
      <div className="c-product-specs__container">
        {!!data?.dimensions && (
          <div className="c-product-specs__item">
            <div className="item-text-header">
              {translationsLoaded ? (
                StringTranslation("dimensions", translations, activeLanguage)
              ) : (
                <h2 className="skeleton skeleton-text-large" />
              )}
            </div>
            <div className="item-text-beverages">{data.dimensions}</div>
          </div>
        )}

        {attributes}
      </div>
    </div>
  );
};

function RenderAttributes(
  item: ProductAttribute,
  identifier: string,
  translations: TranslationsInterface,
  index: number,
  activeLanguage?: LanguageInterface,
) {
  if (!activeLanguage) return null;

  switch (true) {
    case !!item.value &&
      Object.values(item.value).length > 0 &&
      !!Object.values(item.value)[0] &&
      item.type !== "boolean" &&
      item.type !== "text" &&
      item.type !== "number" &&
      item.type !== "metric":
      return (
        <div className="c-product-specs__item" key={index}>
          <div className="item-text-header">
            {Translate(
              item.label as unknown as TranslationsInterface,
              activeLanguage,
            )}
          </div>
          <div className="item-text-beverages">
            {Object.values(item.value).length > 1 ? (
              <ul>
                {Object.values(item.value).map((value, index) => (
                  <li key={index}>
                    {Translate(
                      value as unknown as TranslationsInterface,
                      activeLanguage,
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <span>
                {Translate(
                  Object.values(
                    item.value,
                  )[0] as unknown as TranslationsInterface,
                  activeLanguage,
                )}
              </span>
            )}
          </div>
        </div>
      );
    case !!item.value &&
      item.type === "boolean" &&
      !!Object.values(item.value)[0]:
      return (
        <div className="c-product-specs__item" key={index}>
          <div className="item-text-header">
            {Translate(
              item.label as unknown as TranslationsInterface,
              activeLanguage,
            )}
          </div>
          <div className="item-text-beverages">
            <span>
              {StringTranslation(
                Object.values(item.value)[0] ? "yes" : "no",
                translations,
                activeLanguage,
              )}
            </span>
          </div>
        </div>
      );
    case !!item.value &&
      item.type === "number" &&
      !!Object.values(item.value)[0]:
      return (
        <div className="c-product-specs__item" key={index}>
          <div className="item-text-header">
            {Translate(
              item.label as unknown as TranslationsInterface,
              activeLanguage,
            )}
          </div>
          <div className="item-text-beverages">
            <span>
              {Object.values(item.value)[0].toString()}
            </span>
          </div>
        </div>
      );
    case !!item.value && item.type === "text":
      return (
        <div className="c-product-specs__item" key={index}>
          <div className="item-text-header">
            {Translate(
              item.label as unknown as TranslationsInterface,
              activeLanguage,
            )}
          </div>
          <div className="item-text-beverages">
            <span>
              {Translate(
                item.value as unknown as TranslationsInterface,
                activeLanguage,
              )}
            </span>
          </div>
        </div>
      );
    case !!item.value &&
      item.type === "metric" &&
      !!Object.values(item.value)[0]: {
      let { amount, unit } = Object.values(item.value)[0] as MetricValue;
      amount = amount.replace(/\.0000$/, "");
      unit = convertUnitToAbbreviation(unit);
      return (
        <div className="c-product-specs__item" key={index}>
          <div className="item-text-header">
            {Translate(
              item.label as unknown as TranslationsInterface,
              activeLanguage,
            )}
          </div>
          <div className="item-text-beverages">
            <span>
              {amount} {unit}
            </span>
          </div>
        </div>
      );
    }
    case item.type === "identifier":
      return (
        <div className="c-product-specs__item" key={index}>
          <div className="item-text-header">
            {Translate(
              item.label as unknown as TranslationsInterface,
              activeLanguage,
            )}
          </div>
          <div className="item-text-beverages">
            <span>{identifier}</span>
          </div>
        </div>
      );
    default:
      return null;
  }
}
