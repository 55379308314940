import { ProductGridItem } from "../productRange/ProductGridItem";
import { ProductInterface } from "../../interfaces/ProductInterface";

interface Props {
  category: string;
  resultCount: number;
  products: ProductInterface[];
}

export const ResultCategory = (props: Props) => {
  const { category, resultCount, products } = props;

  return (
    <div className="c-result-category">
      <div className="c-result-category__header">
        <div className="c-result-category__header__title">
          <span>{category}</span>
        </div>
        <div className="c-result-category__header__amount">{resultCount}</div>
      </div>

      {products.length > 0 && (
        <div className="c-result-category__container">
          {products.map((product, index) => (
            <ProductGridItem key={index} product={product} />
          ))}
        </div>
      )}
    </div>
  );
};
