import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface deleteCollectionModalInterface {
  collectionId?: number;
}

const initialState: deleteCollectionModalInterface = {};

const deleteCollectionModal = createSlice({
  name: "deleteCollectionModal",
  initialState,
  reducers: {
    setDeleteCollectionModal: (
      state,
      action: PayloadAction<{
        collectionId?: number;
        showPopup: boolean;
      }>,
    ) => {
      if (action.payload.showPopup) {
        state.collectionId = action.payload.collectionId;
      } else {
        delete state.collectionId;
      }
    },
  },
});

export const { setDeleteCollectionModal } = deleteCollectionModal.actions;
export const deleteCollectionModalReducer = deleteCollectionModal.reducer;
