import { Searchbar } from "../../common/components/general/Searchbar";
import { useState } from "react";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";
import { AccountStateEnum } from "../../common/enums/AccountStateEnum";
import { ProfileTab } from "../../common/components/account/ProfileTab";
import { CompanyTab } from "../../common/components/account/CompanyTab";
import { SettingsTab } from "../../common/components/account/SettingsTab";

const AccountPageContainer = () => {
  const userData = useGetUserInfoQuery();

  const { activeLanguage, communicationEmail } = userData?.data || {
    activeLanguage: null,
    communicationEmail: null,
  };

  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();

  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

  const [navItem, setNavItem] = useState(AccountStateEnum.profile);

  return (
    <div className="p-account">
      <div className="p-account__header">
        <div className="c-breadcrumbs">
          {translationsLoaded ? (
            <>
              <span>
                {StringTranslation("account", translations, activeLanguage)}
              </span>
              <div className="c-breadcrumbs__arrow">
                <img src="/images/arrow-black.svg" alt="arrow" />
              </div>
              <span>
                {StringTranslation(navItem, translations, activeLanguage)}
              </span>
            </>
          ) : (
            <>
              <span className="skeleton skeleton-name"></span>
              <div className="c-breadcrumbs__arrow">
                <img src="/images/arrow-black.svg" alt="arrow" />
              </div>
              <span className="skeleton skeleton-name"></span>
            </>
          )}
        </div>
        <Searchbar activeLanguage={activeLanguage} />
      </div>

      <div className="p-account__content">
        <div className="c-account-nav">
          <div
            className={
              "c-account-nav__item " +
              (navItem === AccountStateEnum.profile ? "is-active" : "")
            }
            onClick={() => setNavItem(AccountStateEnum.profile)}
          >
            {translationsLoaded ? (
              <span>
                {StringTranslation("profile", translations, activeLanguage)}
              </span>
            ) : (
              <div className="skeleton skeleton-name"></div>
            )}
          </div>
          <div
            className={
              "c-account-nav__item " +
              (navItem === AccountStateEnum.company ? "is-active" : "")
            }
            onClick={() => setNavItem(AccountStateEnum.company)}
          >
            {translationsLoaded ? (
              <span>
                {StringTranslation("company", translations, activeLanguage)}
              </span>
            ) : (
              <div className="skeleton skeleton-name"></div>
            )}
          </div>
          <div
            className={
              "c-account-nav__item " +
              (navItem === AccountStateEnum.settings ? "is-active" : "")
            }
            onClick={() => setNavItem(AccountStateEnum.settings)}
          >
            {translationsLoaded ? (
              <span>
                {StringTranslation("settings", translations, activeLanguage)}
              </span>
            ) : (
              <div className="skeleton skeleton-name"></div>
            )}
          </div>
        </div>

        {navItem === AccountStateEnum.profile && <ProfileTab />}
        {navItem === AccountStateEnum.company && <CompanyTab />}
        {navItem === AccountStateEnum.settings && <SettingsTab />}
      </div>

      {navItem !== AccountStateEnum.settings && !!communicationEmail && (
        <div className="c-account-contact">
          {translationsLoaded ? (
            <p className="c-account-contact__text">
              {StringTranslation(
                "is-this-information-not-correct",
                translations,
                activeLanguage,
              )}
            </p>
          ) : (
            <p className="c-account-contact__text skeleton skeleton-title"></p>
          )}

          <a
            href={"mailto:" + communicationEmail}
            className="c-account-contact__button"
          >
            <span>{communicationEmail}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default AccountPageContainer;
