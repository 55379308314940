import { configureStore, Store } from "@reduxjs/toolkit";
import rootReducer, { rootApiSlice } from "./rootReducer";
import { dashboardReducer } from "../pages/dashboardPage/DashboardSlice";
import { productRangeReducer } from "../pages/productRangePage/ProductRangeSlice";
import { productDetailsReducer } from "../pages/productDetail/ProductDetailsSlice";
import { collectionsReducer } from "../pages/collectionsPage/CollectionsSlice";
import {
  accountApiSlice,
  accountReducer,
} from "../pages/accountPage/AccountSlice";
import { collectionsApiSlice } from "../pages/collectionsPage/CollectionsSlice";
import { productDetailsApiSlice } from "../pages/productDetail/ProductDetailsSlice";
import { productRangeApiSlice } from "../pages/productRangePage/ProductRangeSlice";
import { deleteCollectionModalReducer } from "../modals/deleteCollectionModal/DeleteCollectionModalSlice";
import { deleteCollectionProductModalReducer } from "../modals/deleteCollectionProductModal/DeleteCollectionProductModalSlice";
import { editCollectionModalReducer } from "../modals/editCollectionModal/EditCollectionModalSlice";
import { addCollectionProductsModalReducer } from "../modals/addCollectionProductsModal/AddCollectionProductsModalSlice";
import { productGalleryModalReducer } from "../modals/productGalleryModal/productGalleryModalSlice";
import { searchApiSlice, searchReducer } from "../pages/searchPage/SearchSlice";
import { addCollectionModalReducer } from "../modals/addCollectionModal/AddCollectionModalSlice";
import {
  PriceBookReducer,
  priceBookApiSlice,
} from "../pages/priceBookPage/PricebookSlice";
import { QueryMiddleware } from "./QueryMiddleware";

export const store: Store = configureStore({
  reducer: {
    root: rootReducer,
    [rootApiSlice.reducerPath]: rootApiSlice.reducer,
    [accountApiSlice.reducerPath]: accountApiSlice.reducer,
    [collectionsApiSlice.reducerPath]: collectionsApiSlice.reducer,
    [productDetailsApiSlice.reducerPath]: productDetailsApiSlice.reducer,
    [productRangeApiSlice.reducerPath]: productRangeApiSlice.reducer,
    [searchApiSlice.reducerPath]: searchApiSlice.reducer,
    [priceBookApiSlice.reducerPath]: priceBookApiSlice.reducer,
    dashboard: dashboardReducer,
    collections: collectionsReducer,
    productRange: productRangeReducer,
    productDetails: productDetailsReducer,
    account: accountReducer,
    search: searchReducer,
    productGalleryModal: productGalleryModalReducer,
    deleteCollectionModal: deleteCollectionModalReducer,
    deleteCollectionProductModal: deleteCollectionProductModalReducer,
    addCollectionModal: addCollectionModalReducer,
    editCollectionModal: editCollectionModalReducer,
    addCollectionProductsModal: addCollectionProductsModalReducer,
    priceBook: PriceBookReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(
      rootApiSlice.middleware,
      accountApiSlice.middleware,
      collectionsApiSlice.middleware,
      productDetailsApiSlice.middleware,
      productRangeApiSlice.middleware,
      searchApiSlice.middleware,
      priceBookApiSlice.middleware,
      QueryMiddleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
