import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ProductGalleryModalSliceInterface,
  setActive,
} from "./productGalleryModalSlice";

export const ProductGalleryModal = () => {
  const { lightboxSlices, thumbnailLightBoxSettings, isActive } =
    useAppSelector(
      (state) => state.productGalleryModal as ProductGalleryModalSliceInterface,
    );

  const dispatch = useAppDispatch();
  const lightboxThumbnailsSetting = thumbnailLightBoxSettings;
  const videoPlugin = Video;

  // Return null if modal is not active
  if (!isActive) return null;

  return (
    <div className="p-product-details__lightbox">
      <Lightbox
        open={() => dispatch(setActive(true))}
        close={() => dispatch(setActive(false))}
        plugins={[Thumbnails, videoPlugin]}
        thumbnails={lightboxThumbnailsSetting}
        slides={lightboxSlices}
        render={{
          slide: ({
            slide,
            rect,
          }: {
            slide: { type: string; src: string; width: number; height: number };
            rect: { width: number; height: number };
          }) => {
            return slide.type === "video-custom" ? (
              <iframe
                title={slide.src + "/" + slide.width + "/" + slide.height}
                width={Math.min(
                  slide.width,
                  rect.width,
                  (slide.width * rect.height) / slide.height,
                )}
                height={Math.min(
                  slide.height,
                  rect.height,
                  (slide.height * rect.width) / slide.width,
                )}
                src={slide.src}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : undefined;
          },
          thumbnail: ({
            slide,
          }: {
            slide: { type: string; poster: string };
          }) =>
            slide.type === "video-custom" ? (
              <img
                src={slide.poster}
                style={{ width: "auto", height: "100%" }}
                alt=""
              />
            ) : undefined,
        }}
      />
    </div>
  );
};
