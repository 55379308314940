// import {ExportBar} from "./ExportBar";
import { CollectionInterface } from "../../interfaces/CollectionInterface";
import { CollectionProducts } from "./CollectionProducts";
import { useState } from "react";
import { setDeleteCollectionModal } from "../../../modals/deleteCollectionModal/DeleteCollectionModalSlice";
import { useAppDispatch } from "../../../app/hooks";
import { setEditCollectionModal } from "../../../modals/editCollectionModal/EditCollectionModalSlice";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";

interface Props {
  collection: CollectionInterface;
}

export const CollectionItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();

  const { collection } = props;

  const [active, setActive] = useState(false);

  // const exportTypes = [
  //     {
  //         id: 1,
  //         label: 'Pricelist',
  //         type: 'csv_pricelist',
  //         fileType: 'CSV',
  //         color: 'green',
  //     },
  //     {
  //         id: 2,
  //         label: 'Pricelist',
  //         type: 'pdf_pricelist',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  //     {
  //         id: 3,
  //         label: 'Catalog',
  //         type: 'pdf_catalog',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  //     {
  //         id: 4,
  //         label: 'Productsheet',
  //         type: 'pdf_productsheet',
  //         fileType: 'PDF',
  //         color: 'pink',
  //     },
  // ]

  if (collection) {
    return (
      <div className={"c-collection " + (active ? "is-active" : "")}>
        <div className="c-collection__header">
          <div
            className="c-collection__name"
            onClick={() => setActive(!active)}
          >
            <div className="c-collection__name__icon">
              {collection.name.charAt(0)}
            </div>
            <span>{collection.name}</span>
          </div>

          <div
            className="c-collection__categories"
            onClick={() => setActive(!active)}
          >
            <div className="c-collection__category">
              <span>{collection.machineryCount + " machinery"}</span>
            </div>
            <div className="c-collection__category">
              <span>{collection.accessoriesCount + " accessories"}</span>
            </div>
          </div>

          <div className="c-collection__buttons">
            <div className="c-collection__buttons__edit">
              <button
                className="c-collection__button c-collection__button--edit"
                onClick={() => {
                  dispatch(
                    setEditCollectionModal({
                      showPopup: true,
                      collectionId: collection.id,
                    }),
                  );
                }}
              >
                <img src="/images/pencil.svg" alt="delete" />
                <span>
                  {StringTranslation(
                    "edit-name",
                    translations,
                    activeLanguage,
                  ).toLowerCase()}
                </span>
              </button>
              <button
                className="c-collection__button c-collection__button--delete"
                onClick={() => {
                  dispatch(
                    setDeleteCollectionModal({
                      showPopup: true,
                      collectionId: collection.id,
                    }),
                  );
                }}
              >
                <img src="/images/cross.svg" alt="delete" />
                <span>
                  {StringTranslation(
                    "delete",
                    translations,
                    activeLanguage,
                  ).toLowerCase()}
                </span>
              </button>
            </div>
            <div
              className="c-collection__button c-collection__button--expand"
              onClick={() => setActive(!active)}
            >
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>

        <div className="c-collection__content">
          {collection.machinery.length > 0 && (
            <CollectionProducts
              category={StringTranslation(
                "machinery",
                translations,
                activeLanguage,
              ).toLowerCase()}
              collection={collection}
              productCount={collection.machineryCount}
              productPages={collection.machinery}
            />
          )}

          {collection.accessories.length > 0 && (
            <CollectionProducts
              category={StringTranslation(
                "accessories",
                translations,
                activeLanguage,
              ).toLowerCase()}
              collection={collection}
              productCount={collection.accessoriesCount}
              productPages={collection.accessories}
            />
          )}

          {collection.spareParts.length > 0 && (
            <CollectionProducts
              category={StringTranslation(
                "spare-parts",
                translations,
                activeLanguage,
              ).toLowerCase()}
              collection={collection}
              productCount={collection.sparePartsCount}
              productPages={collection.spareParts}
            />
          )}

          {/*<div className="c-collection__content__export">*/}
          {/*    <ExportBar collection={collection} exportTypes={exportTypes}/>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
  return null;
};
