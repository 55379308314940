import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDeleteCollectionModal } from "./DeleteCollectionModalSlice";
import {
  useDeleteCollectionMutation,
  useLazyGetCollectionsQuery,
} from "../../pages/collectionsPage/CollectionsSlice";
import { useState } from "react";
import { MoonLoader } from "react-spinners";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";

export const DeleteCollectionModalContainer = () => {
  const dispatch = useAppDispatch();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();

  const { collections } = useAppSelector((state) => state.collections);
  const { collectionId } = useAppSelector(
    (state) => state.deleteCollectionModal,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteCollectionTrigger] = useDeleteCollectionMutation();
  const [collectionTrigger] = useLazyGetCollectionsQuery();

  const collectionName =
    collections.find((item: { id: number }) => item.id === collectionId)
      ?.name || "";

  return (
    <div className="c-modal">
      <div className="c-modal__bg" />
      <div className="c-modal__content">
        {!loading ? (
          <>
            <button
              className="c-modal__close"
              onClick={() => {
                dispatch(
                  setDeleteCollectionModal({
                    showPopup: false,
                  }),
                );
              }}
            >
              <img
                className="c-modal__icon"
                src="/images/cross.svg"
                alt="cross"
              />
            </button>
            <header className="c-modal__header">
              <h2>
                {StringTranslation(
                  "do-you-want-to-delete-this-item",
                  translations,
                  activeLanguage,
                ) ?? ""}
              </h2>
            </header>
            <div className="c-modal__body">
              <p>
                {StringTranslation(
                  "are-you-sure-you-want-to-delete-the",
                  translations,
                  activeLanguage,
                ) +
                  " " +
                  collectionName +
                  " " +
                  StringTranslation(
                    "collection",
                    translations,
                    activeLanguage,
                  ) +
                  "?"}
              </p>

              <div className="c-modal__body__buttons">
                <button
                  className="c-btn c-btn--primary"
                  onClick={() => {
                    setLoading(true);
                    deleteCollectionTrigger(collectionId).then(() => {
                      collectionTrigger().then(() => {
                        setLoading(false);
                        dispatch(
                          setDeleteCollectionModal({
                            showPopup: false,
                          }),
                        );
                      });
                    });
                  }}
                >
                  <span>
                    {StringTranslation(
                      "yes-im-sure",
                      translations,
                      activeLanguage,
                    ) ?? ""}
                  </span>
                </button>
                <button
                  className="c-btn c-btn--secondary"
                  onClick={() => {
                    dispatch(
                      setDeleteCollectionModal({
                        showPopup: false,
                      }),
                    );
                  }}
                >
                  <span>
                    {StringTranslation(
                      "cancel",
                      translations,
                      activeLanguage,
                    ) ?? ""}
                  </span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="c-loader">
            <MoonLoader color={"#E52713FF"} />
          </div>
        )}
      </div>
    </div>
  );
};
