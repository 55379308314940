import { createRoot } from "react-dom/client";
import "./common/styles/main.scss";
import AuthWrapper from "./app/AuthWrapper";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import {
  AuthProvider,
  TAuthConfig,
  TRefreshTokenExpiredEvent,
} from "react-oauth2-code-pkce";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://583c25791834909a57db3bff4e44ace6@o425936.ingest.us.sentry.io/4507136427884544",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    "https://b2b-dealer-bravilor.mw-test.unframed.app",
    "https://b2b-dealer-bravilor.mw-acc.unframed.app",
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  authorizationEndpoint: process.env.REACT_APP_AUTH_URL + "/oauth/authorize",
  tokenEndpoint: process.env.REACT_APP_AUTH_URL + "/oauth/token",
  redirectUri: process.env.REACT_APP_APP_URL + "/",
  extraAuthParameters: { prompt: "login" },
  storage: "local",
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    window.confirm(
      "Session expired. Refresh page to continue using the site?",
    ) && event.login(),
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <AuthProvider authConfig={authConfig}>
      <Provider store={store}>
        <AuthWrapper />
      </Provider>
    </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
