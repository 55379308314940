import React, { Key, useEffect, useState } from "react";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useSetUserLanguageMutation,
} from "../../../app/rootReducer";
import { LanguageInterface } from "../../interfaces/LanguageInterface";
import { CountryInterface } from "../../interfaces/CountryInterface";
import { useLazyGetProductRangeQuery } from "../../../pages/productRangePage/ProductRangeSlice";
import { ProductTypeEnum } from "../../enums/ProductTypeEnum";
import { SelectedFilterListInterface } from "../../interfaces/SelectedFilterListInterface";
import { useAppSelector } from "../../../app/hooks";
import { StringTranslation } from "../../utils/StringTranslation";

interface LanguagePickerProps {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setCountryActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LanguagePicker: React.FC<LanguagePickerProps> = ({
  active,
  setActive,
  setCountryActive,
}) => {
  const { selectedFilters, productType } = useAppSelector(
    (state) => state.productRange,
  );

  const { data: translations } = useGetTranslationsQuery();
  const [setUserLanguageTrigger] = useSetUserLanguageMutation();
  const [getUserInfoTrigger] = useLazyGetUserInfoQuery();
  const [getProductRangeTrigger] = useLazyGetProductRangeQuery();

  const { data } = useGetUserInfoQuery();
  const { languages, activeLanguage, activeCountry } = data || {
    languages: [],
    activeLanguage: null,
    activeCountry: null,
  };

  const [activeLanguageState, setActiveLanguageState] =
    useState<LanguageInterface | null>(activeLanguage);

  useEffect(() => {
    setActiveLanguageState(activeLanguage);
  }, [activeLanguage]);

  if (
    languages &&
    languages.length > 1 &&
    activeLanguageState &&
    activeCountry
  ) {
    return (
      <div className="c-language-picker__wrapper">
        <div className="c-language-picker__label">
          <span>
            {StringTranslation("language", translations, activeLanguage)}:
          </span>
        </div>
        <div className={"c-language-picker " + (active ? "is-active" : "")}>
          <button
            className="c-language-picker__btn"
            onClick={() =>
              openLanguagePicker(active, setActive, setCountryActive)
            }
          >
            <div>
              <span>{activeLanguageState.name}</span>
            </div>
            <img className="img-arrow" src="/images/arrow-down.svg" alt="" />
          </button>
          <div className="c-language-picker__container">
            <ul>
              {languages
                .filter(
                  (language: LanguageInterface) =>
                    language.id !== activeLanguageState.id,
                )
                .map(
                  (
                    language: LanguageInterface,
                    index: Key | null | undefined,
                  ) => (
                    <li
                      key={index}
                      onClick={() => {
                        setActive(!active);
                        setLanguage(
                          language,
                          activeCountry,
                          productType,
                          selectedFilters,
                          getUserInfoTrigger,
                          setUserLanguageTrigger,
                          getProductRangeTrigger,
                          setActiveLanguageState,
                        );
                      }}
                    >
                      <span>{language.name}</span>
                    </li>
                  ),
                )}
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const openLanguagePicker = (
  active: boolean,
  setActive: React.Dispatch<React.SetStateAction<boolean>>,
  setCountryActive: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setActive(!active);
  setCountryActive(false);
};

const setLanguage = (
  language: LanguageInterface,
  country: CountryInterface,
  productType: ProductTypeEnum,
  selectedFilters: SelectedFilterListInterface[],
  getUserInfoTrigger: ReturnType<typeof useLazyGetUserInfoQuery>[0],
  setUserLanguageTrigger: ReturnType<typeof useSetUserLanguageMutation>[0],
  getProductRangeTrigger: ReturnType<typeof useLazyGetProductRangeQuery>[0],
  setActiveLanguageState: React.Dispatch<
    React.SetStateAction<LanguageInterface | null>
  >,
) => {
  setActiveLanguageState(language);
  setUserLanguageTrigger({
    languageId: language.id,
    countryId: country.id,
  }).then(() => {
    getUserInfoTrigger();
    getProductRangeTrigger({ filters: selectedFilters, type: productType });
  });
};
