import { StringTranslation } from "../../utils/StringTranslation";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";

export const CompanyTab = () => {
  const userData = useGetUserInfoQuery();

  const { activeLanguage, dealer } = userData?.data || { activeLanguage: null };

  const {
    logo: companyLogo,
    name: companyName,
    email: companyEmail,
    phoneNumber: companyPhoneNumber,
    address: companyAddress,
  } = dealer || {};
  const { street, postal_code, city } = companyAddress || {};

  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

  return (
    <>
      <div className="c-account-content c-account-content--company-info">
        <div className="c-account-content__header">
          {translationsLoaded ? (
            <h1>
              {StringTranslation(
                "company-information",
                translations,
                activeLanguage,
              )}
            </h1>
          ) : (
            <h1 className="skeleton skeleton-title"></h1>
          )}
        </div>
        <div className="c-account-content__wrapper">
          <div className="c-account-content__column">
            {translationsLoaded ? (
              <span className="c-account-content__column__text c-account-content__column__text--label">
                {StringTranslation("company", translations, activeLanguage)}
              </span>
            ) : (
              <span className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
            )}

            {translationsLoaded ? (
              <>
                {companyName ? (
                  <span className="c-account-content__column__text">
                    {companyName}
                  </span>
                ) : (
                  <span className="c-account-content__column__text">-</span>
                )}
              </>
            ) : (
              <span className="c-account-content__column__text skeleton skeleton-name"></span>
            )}
          </div>

          <div className="c-account-content__column c-account-content__column--logo">
            {translationsLoaded ? (
              <span className="c-account-content__column__text c-account-content__column__text--label">
                {StringTranslation("logo", translations, activeLanguage)}
              </span>
            ) : (
              <span className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
            )}

            {translationsLoaded ? (
              <>
                {!!companyLogo && (
                  <div className="c-account-content__column__logo">
                    <img src={companyLogo ?? ""} alt="logo" />
                  </div>
                )}
              </>
            ) : (
              <div className="c-account-content__column__logo skeleton skeleton-carousel"></div>
            )}
          </div>
        </div>
      </div>

      <div className="c-account-content c-account-content--company-location">
        <div className="c-account-content__header">
          {translationsLoaded ? (
            <h1>
              {StringTranslation("location", translations, activeLanguage)}
            </h1>
          ) : (
            <h1 className="skeleton skeleton-title"></h1>
          )}
        </div>
        <div className="c-account-content__wrapper">
          <div className="c-account-content__column">
            {translationsLoaded ? (
              <span className="c-account-content__column__text c-account-content__column__text--label">
                {StringTranslation(
                  "location-name",
                  translations,
                  activeLanguage,
                )}
              </span>
            ) : (
              <span className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
            )}

            {translationsLoaded ? (
              <>
                {companyName ? (
                  <span className="c-account-content__column__text">
                    {companyName}
                  </span>
                ) : (
                  <span className="c-account-content__column__text">-</span>
                )}
              </>
            ) : (
              <span className="c-account-content__column__text skeleton skeleton-name"></span>
            )}
          </div>

          <div className="c-account-content__column">
            {translationsLoaded ? (
              <span className="c-account-content__column__text c-account-content__column__text--label">
                {StringTranslation(
                  "general-email-addresses",
                  translations,
                  activeLanguage,
                )}
              </span>
            ) : (
              <span className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
            )}

            {translationsLoaded ? (
              <>
                {companyEmail ? (
                  <span className="c-account-content__column__text">
                    {companyEmail}
                  </span>
                ) : (
                  <span className="c-account-content__column__text">-</span>
                )}
              </>
            ) : (
              <span className="c-account-content__column__text skeleton skeleton-name"></span>
            )}
          </div>

          <div className="c-account-content__column">
            {translationsLoaded ? (
              <span className="c-account-content__column__text c-account-content__column__text--label">
                {StringTranslation(
                  "general-phone-number",
                  translations,
                  activeLanguage,
                )}
              </span>
            ) : (
              <span className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
            )}

            {translationsLoaded ? (
              <>
                {companyPhoneNumber ? (
                  <span className="c-account-content__column__text">
                    {companyPhoneNumber}
                  </span>
                ) : (
                  <span className="c-account-content__column__text">-</span>
                )}
              </>
            ) : (
              <span className="c-account-content__column__text skeleton skeleton-name"></span>
            )}
          </div>
        </div>

        {!!companyAddress && (
          <div className="c-account-content__column__sub-block">
            <div className="c-account-content__header">
              {translationsLoaded ? (
                <h3>
                  {StringTranslation("addresses", translations, activeLanguage)}
                </h3>
              ) : (
                <h3 className="skeleton skeleton-title"></h3>
              )}
            </div>

            <div className="c-account-content__column">
              {translationsLoaded ? (
                <span className="c-account-content__column__text c-account-content__column__text--label">
                  {StringTranslation(
                    "visiting-address",
                    translations,
                    activeLanguage,
                  )}
                </span>
              ) : (
                <span className="c-account-content__column__text c-account-content__column__text--label skeleton skeleton-name"></span>
              )}

              {translationsLoaded ? (
                <>
                  {!!street || !!postal_code || !!city ? (
                    <span className="c-account-content__column__text">
                      {street}, {postal_code}, {city}
                    </span>
                  ) : (
                    <span className="c-account-content__column__text">-</span>
                  )}
                </>
              ) : (
                <span className="c-account-content__column__text skeleton skeleton-name"></span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
