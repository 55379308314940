import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Image } from "../../common/interfaces/ProductDetailsDataInterface";

export interface ProductGalleryModalSliceInterface {
  isActive: boolean;
  lightboxSlices: {
    src: string;
    width: number;
    height: number;
    type?: "video";
    poster?: string;
  }[];
  thumbnailLightBoxSettings: {
    width: number;
    height: number;
    border: number;
    borderRadius: number;
    padding: number;
    gap: number;
    showToggle: boolean;
    position: "start" | "end";
  };
}

const initialState: ProductGalleryModalSliceInterface = {
  isActive: false,
  lightboxSlices: [],
  thumbnailLightBoxSettings: {
    width: 90,
    height: 120,
    border: 1,
    borderRadius: 24,
    padding: 6,
    gap: 16,
    showToggle: false,
    position: "start",
  },
};

const productGalleryModalSlice = createSlice({
  name: "productGalleryModal",
  initialState,
  reducers: {
    setLightboxSlices: (state, action: PayloadAction<Image[]>) => {
      state.lightboxSlices = action.payload.map((image) => {
        return {
          src: image.type === "video" ? image.url : image.converted.HD,
          width: 1080,
          height: 1620,
          type:
            image.type === "video" ? ("video-custom" as "video") : undefined,
          poster: image.type === "video" ? "image.url" : undefined,
        };
      });
    },
    setActive: (state, action) => {
      state.isActive = action.payload;
    },
  },
});

export const { setLightboxSlices, setActive } =
  productGalleryModalSlice.actions;

export const productGalleryModalReducer = productGalleryModalSlice.reducer;
