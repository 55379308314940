import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface deleteCollectionProductModalInterface {
  collectionId?: number;
  productId?: number;
}

const initialState: deleteCollectionProductModalInterface = {};

const deleteCollectionProductModal = createSlice({
  name: "deleteCollectionProductModal",
  initialState,
  reducers: {
    setDeleteCollectionProductModal: (
      state,
      action: PayloadAction<{
        collectionId?: number;
        productId?: number;
        showPopup: boolean;
      }>,
    ) => {
      if (action.payload.showPopup) {
        state.collectionId = action.payload.collectionId;
        state.productId = action.payload.productId;
      } else {
        delete state.productId;
        delete state.collectionId;
      }
    },
  },
});

export const { setDeleteCollectionProductModal } =
  deleteCollectionProductModal.actions;
export const deleteCollectionProductModalReducer =
  deleteCollectionProductModal.reducer;
