export const ProductCarouselNavigationItems = ({
  next,
  previous,
}: {
  next: () => void;
  previous: () => void;
}) => {
  return (
    <div className="carousel-button-group">
      <div
        className={
          "c-product-carousel__navigation__item c-product-carousel__navigation__item--left"
        }
        onClick={() => previous()}
      >
        <img src="/images/arrow-black.svg" alt="arrow-down" />
      </div>
      <div
        className={
          "c-product-carousel__navigation__item c-product-carousel__navigation__item--right"
        }
        onClick={() => next()}
      >
        <img src="/images/arrow-black.svg" alt="arrow-down" />
      </div>
    </div>
  );
};
