export const breakPointsBannerSlider = {
  desktop: {
    breakpoint: { max: 4000, min: 1247 },
    items: 3.4,
  },
  tablet: {
    breakpoint: { max: 1247, min: 800 },
    items: 2.2,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1.2,
  },
};

export const breakPointsRelatedSlider = {
  desktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1440, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

export const breakPointsOlderModelsSlider = {
  desktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 6,
  },
  smallDesktop: {
    breakpoint: { max: 1400, min: 1023 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1023, min: 800 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 800, min: 600 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};
