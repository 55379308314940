import { StringTranslation } from "../../utils/StringTranslation";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import {
  useGetAuthSettingsQuery,
  useSendAuthCodeMutation,
} from "../../../pages/accountPage/AccountSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { BarLoader } from "react-spinners";
import { TwoFactorTypeEnum } from "../../enums/TwoFactorTypeEnum";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface Props {
  selectedNewMethod: string | null;
  authCodeIsSent: boolean;
  phoneNumberInput?: string;
  setAuthCodeIsSent: (isSent: boolean) => void;
  setPhoneNumberInput?: (phoneNumber: string) => void;
}

export const SettingsSendCode = (props: Props) => {
  const userData = useGetUserInfoQuery();
  const [sendAuthCodeTrigger, sendAuthCodeResult] = useSendAuthCodeMutation();

  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const { data: authSettings } = useGetAuthSettingsQuery();

  const {
    selectedNewMethod,
    authCodeIsSent,
    phoneNumberInput,
    setAuthCodeIsSent,
    setPhoneNumberInput,
  } = props;
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { authentication_methods } = authSettings || {
    authentication_methods: [],
    current_authentication_method: "",
  };

  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;
  const selectedMethod = authentication_methods.find(
    (method) => method.authentication_method === selectedNewMethod,
  );

  const isFetchBaseQueryError = (
    error: unknown,
  ): error is FetchBaseQueryError => {
    return (
      !!error && typeof (error as FetchBaseQueryError).status !== "undefined"
    );
  };

  return (
    <>
      {!!selectedMethod && (
        <>
          {selectedNewMethod === TwoFactorTypeEnum.sms && (
            <>
              {translationsLoaded ? (
                <label>
                  {StringTranslation(
                    "phone-number",
                    translations,
                    activeLanguage,
                  )}
                  :
                </label>
              ) : (
                <label className="skeleton skeleton-title"></label>
              )}
            </>
          )}
          <div className="c-send-code-form">
            {selectedNewMethod === TwoFactorTypeEnum.sms &&
              setPhoneNumberInput && (
                <PhoneInput
                  className="c-send-code-form__input"
                  value={phoneNumberInput ?? ""}
                  onChange={(value) =>
                    setPhoneNumberInput && setPhoneNumberInput(value ?? "")
                  }
                />
              )}
            <button
              className="c-btn c-btn--primary c-btn--fit-content"
              disabled={sendAuthCodeResult.isLoading}
              onClick={() => {
                setAuthCodeIsSent(true);
                sendAuthCodeTrigger({
                  authentication_method: selectedMethod.authentication_method,
                  phone_number: phoneNumberInput || null,
                });
              }}
            >
              <>
                {sendAuthCodeResult.isLoading ? (
                  <div className={"c-loader"}>
                    <BarLoader color={"#000000"} />
                  </div>
                ) : (
                  <>
                    {translationsLoaded ? (
                      <span>
                        {selectedNewMethod === TwoFactorTypeEnum.email
                          ? StringTranslation(
                              "send-email",
                              translations,
                              activeLanguage,
                            )
                          : StringTranslation(
                              "send-sms",
                              translations,
                              activeLanguage,
                            )}
                      </span>
                    ) : (
                      <span className="skeleton skeleton-name"></span>
                    )}
                  </>
                )}
              </>
            </button>
          </div>
        </>
      )}

      {authCodeIsSent && !sendAuthCodeResult.isLoading && (
        <>
          {isFetchBaseQueryError(sendAuthCodeResult?.error) ? (
            <p
              style={{
                marginTop: "1em",
                fontWeight: "bold",
                fontSize: "1.6rem",
              }}
            >
              {selectedNewMethod === TwoFactorTypeEnum.email
                ? StringTranslation("mail-failed", translations, activeLanguage)
                : StringTranslation("sms-failed", translations, activeLanguage)}
            </p>
          ) : (
            <p
              style={{
                marginTop: "1em",
                fontSize: "1.6rem",
              }}
            >
              {selectedNewMethod === TwoFactorTypeEnum.email
                ? StringTranslation(
                    "mail-is-send",
                    translations,
                    activeLanguage,
                  )
                : StringTranslation(
                    "sms-is-send",
                    translations,
                    activeLanguage,
                  )}
            </p>
          )}
        </>
      )}
    </>
  );
};
