import { StringTranslation } from "../../utils/StringTranslation";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
} from "../../../app/rootReducer";
import {
  useGetAuthSettingsQuery,
  useLazyGetAuthSettingsQuery,
  useSetAuthMethodMutation,
} from "../../../pages/accountPage/AccountSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import ReactInputVerificationCode from "react-input-verification-code";
import { BarLoader } from "react-spinners";

interface Props {
  selectedNewMethod: string | null;
  phoneNumberInput?: string;
  authCodeInput: string;
  authMethodIsSet: boolean;
  setAuthCodeInput: (code: string) => void;
  setAuthMethodIsSet: (isSet: boolean) => void;
}

export const SettingsAuthenticationInput = (props: Props) => {
  const userData = useGetUserInfoQuery();

  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const { data: authSettings } = useGetAuthSettingsQuery();

  const [authSettingsTrigger] = useLazyGetAuthSettingsQuery();
  const [setAuthMethodTrigger, setAuthMethodResult] =
    useSetAuthMethodMutation();
  const [getUserInfoTrigger] = useLazyGetUserInfoQuery();

  const {
    selectedNewMethod,
    authCodeInput,
    authMethodIsSet,
    phoneNumberInput,
    setAuthCodeInput,
    setAuthMethodIsSet,
  } = props;

  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { authentication_methods } = authSettings || {
    authentication_methods: [],
    current_authentication_method: "",
  };

  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;
  const selectedMethod = authentication_methods.find(
    (method) => method.authentication_method === selectedNewMethod,
  );

  const isFetchBaseQueryError = (
    error: unknown,
  ): error is FetchBaseQueryError => {
    return (
      !!error && typeof (error as FetchBaseQueryError).status !== "undefined"
    );
  };

  const hasMessage = (data: unknown): data is { message: string } => {
    return !!data && typeof (data as { message: string }).message === "string";
  };

  return (
    <>
      {translationsLoaded ? (
        <label>
          {StringTranslation(
            "authentication-code",
            translations,
            activeLanguage,
          )}
          :
        </label>
      ) : (
        <label className="skeleton skeleton-title"></label>
      )}
      {!!selectedMethod && (
        <div className="c-account-content__settings-container__form">
          <ReactInputVerificationCode
            length={6}
            type="text"
            value={authCodeInput}
            onChange={(code) => {
              setAuthCodeInput(code);
            }}
            autoFocus
            placeholder=""
          />
          <button
            className="c-btn c-btn--primary"
            disabled={setAuthMethodResult.isLoading || !authCodeInput}
            onClick={() => {
              setAuthMethodIsSet(true);
              setAuthMethodTrigger({
                authentication_method: selectedMethod.authentication_method,
                code: authCodeInput,
                phone_number: phoneNumberInput || "",
              }).then((result) => {
                if (!result.error) {
                  setAuthCodeInput("");
                  authSettingsTrigger();
                  getUserInfoTrigger();
                  return;
                }
              });
            }}
          >
            {setAuthMethodResult.isLoading ? (
              <div className={"c-loader"}>
                <BarLoader color={"#000000"} />
              </div>
            ) : (
              <>
                {translationsLoaded ? (
                  <span>
                    {StringTranslation(
                      "activate",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                ) : (
                  <span className="skeleton skeleton-name"></span>
                )}
              </>
            )}
          </button>
        </div>
      )}
      {authMethodIsSet &&
        !setAuthMethodResult.isLoading &&
        isFetchBaseQueryError(setAuthMethodResult?.error) &&
        hasMessage(setAuthMethodResult.error.data) && (
          <>
            <br />{" "}
            <p
              style={{
                marginTop: "1em",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              {setAuthMethodResult.error.data?.message}
            </p>
          </>
        )}

      {authMethodIsSet &&
        !setAuthMethodResult.isLoading &&
        !setAuthMethodResult.error &&
        setAuthMethodResult.data && (
          <>
            <br />{" "}
            <p
              style={{
                marginTop: "1em",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              {StringTranslation(
                "preference-saved",
                translations,
                activeLanguage,
              )}
            </p>
          </>
        )}
    </>
  );
};
