import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ProductInterface } from "../../common/interfaces/ProductInterface";
import { CategoryListInterface } from "../../common/interfaces/CategoryListInterface";
import { SelectedFilterListInterface } from "../../common/interfaces/SelectedFilterListInterface";
import { ProductTypeEnum } from "../../common/enums/ProductTypeEnum";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../common/enums/ApiEnum";
import { ProductRangeDataInterface } from "../../common/interfaces/ProductRangeDataInterface";
import { FilterListInterface } from "../../common/interfaces/FilterListInterface";
import { apiBaseQuery } from "../../common/utils/apiBaseQuery";
import { ProductRangeSubCategoryMapper } from "../../common/utils/productRangeSubCategoryMapper";

interface ProductRangePageInterface {
  gridLayout: boolean;
  mobileFiltersActive: boolean;
  productType: ProductTypeEnum;
  selectedProducts: ProductInterface[];
  categories?: CategoryListInterface[];
  selectedFilters: SelectedFilterListInterface[];
  disableCheckbox?: boolean;
}

const initialState: ProductRangePageInterface = {
  gridLayout: true,
  mobileFiltersActive: false,
  productType: ProductTypeEnum.products,
  selectedProducts: [],
  selectedFilters: [],
  disableCheckbox: false,
};

const productRangeSlice = createSlice({
  name: "productRange",
  initialState,
  reducers: {
    setGridLayout: (state, action: PayloadAction<boolean>) => {
      state.gridLayout = action.payload;
    },
    setProductType: (state, action: PayloadAction<ProductTypeEnum>) => {
      state.productType = action.payload;
    },
    setMobileFiltersActive: (state, action: PayloadAction<boolean>) => {
      state.mobileFiltersActive = action.payload;
      if (state.mobileFiltersActive) {
        document.body.style.overflow = "hidden";
      }
      if (!state.mobileFiltersActive) {
        document.body.style.overflow = "auto";
      }
    },
    selectProduct: (state, action: PayloadAction<ProductInterface>) => {
      if (
        current(state.selectedProducts).filter(
          (product) => product.id === action.payload.id,
        ).length > 0
      ) {
        state.disableCheckbox = false;
        state.selectedProducts = current(state.selectedProducts).filter(
          (product) => product.id !== action.payload.id,
        );
      } else {
        state.disableCheckbox = true;
        if (state.selectedProducts.length < 50) {
          state.selectedProducts.push(action.payload);
          state.disableCheckbox = state.selectedProducts.length === 50;
        }
      }
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = [];
    },
    selectFilter: (
      state,
      action: PayloadAction<{ filter: { id: number }; option: { id: string } }>,
    ) => {
      const filterId = action.payload.filter.id;
      const optionId = action.payload.option.id;

      if (!state.selectedFilters[filterId]) {
        state.selectedFilters[filterId] = {
          id: filterId,
          options: [optionId],
        };
      } else if (
        state.selectedFilters[filterId] &&
        state.selectedFilters[filterId].options.filter(
          (option) => option === optionId,
        ).length > 0
      ) {
        if (
          state.selectedFilters[filterId].options.filter(
            (option) => option !== optionId,
          ).length === 0
        ) {
          delete state.selectedFilters[filterId];
        } else {
          state.selectedFilters[filterId] = {
            id: filterId,
            options: state.selectedFilters[filterId].options.filter(
              (option) => option !== optionId,
            ),
          };
        }
      } else if (state.selectedFilters[filterId]) {
        state.selectedFilters[filterId] = {
          id: filterId,
          options: [...state.selectedFilters[filterId].options, optionId],
        };
      }
    },
    resetFilter: (state) => {
      state.selectedFilters = [];
    },
  },
});

export const productRangeApiSlice = createApi({
  reducerPath: "productRangeApi",
  baseQuery: apiBaseQuery(),
  tagTypes: [],
  endpoints: (builder) => ({
    getProductRangeFilter: builder.query<FilterListInterface[], void>({
      query: () => ApiEnum.PRODUCT_RANGE + "/filter-attributes",
    }),
    getProductRange: builder.query<
      ProductRangeDataInterface,
      {
        filters: SelectedFilterListInterface[];
        type: ProductTypeEnum;
        includeArchived?: boolean;
      }
    >({
      query: (params) => {
        const filters = params.filters.filter((filter) => filter !== undefined);
        return {
          url: ApiEnum.PRODUCT_RANGE,
          method: "POST",
          body: {
            type: params.type,
            filters: filters,
            includeArchived: params.includeArchived,
          },
        };
      },
      transformResponse: (response: ProductRangeDataInterface, _) => {
        response.categories = response.categories.map(
          (category: CategoryListInterface) => {
            category.products = response.products.filter(
              (product: ProductInterface) =>
                product.categories.includes(category.id) &&
                product.prices &&
                product.prices.length > 0,
            );
            category = ProductRangeSubCategoryMapper(response, category);
            return category;
          },
        );
        return response;
      },
    }),
  }),
});

export const {
  selectProduct,
  clearSelectedProducts,
  selectFilter,
  setGridLayout,
  setProductType,
  setMobileFiltersActive,
  resetFilter,
} = productRangeSlice.actions;
export const productRangeReducer = productRangeSlice.reducer;
export const {
  useGetProductRangeFilterQuery,
  useGetProductRangeQuery,
  useLazyGetProductRangeQuery,
} = productRangeApiSlice;
