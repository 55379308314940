import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiEnum } from "../../common/enums/ApiEnum";
import { CollectionInterface } from "../../common/interfaces/CollectionInterface";
import { ProductInterface } from "../../common/interfaces/ProductInterface";
import { collectionProductMapper } from "../../common/utils/collectionProductMapper";
import { useAppAxios } from "../../app/hooks";
import { apiBaseQuery } from "../../common/utils/apiBaseQuery";

interface CollectionPayloadInterface {
  id: number;
  name: string;
  products: ProductInterface[];
}

interface CollectionsPageInterface {
  collections: CollectionInterface[];
}

const initialState: CollectionsPageInterface = {
  collections: [],
};

export const excelExportPriceList = async (params: {
  collectionId: number;
  languageId: number;
}) => {
  return await useAppAxios
    .get(
      ApiEnum.COLLECTIONS +
        "/export/price-list/" +
        params.collectionId +
        "/" +
        params.languageId,
      {
        method: "GET",
        responseType: "blob",
      },
    )
    .then(function (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      return error;
    });
};

const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    mapCollection: (
      state,
      action: PayloadAction<CollectionPayloadInterface[] | undefined>,
    ) => {
      if (action.payload) {
        state.collections = [];

        action.payload.forEach(
          (payloadCollection: CollectionPayloadInterface) => {
            const numberOfProducts = 10;

            const collection: CollectionInterface = {
              id: payloadCollection.id,
              name: payloadCollection.name,
              productIds: payloadCollection.products.map(
                (product) => product.id,
              ),
              machineryCount: 0,
              accessoriesCount: 0,
              sparePartsCount: 0,
              machinery: [],
              accessories: [],
              spareParts: [],
            };

            const machinery = payloadCollection.products.filter(
              (product) =>
                product.productFamily.code !== "accessories" &&
                product.productFamily.code !== "spare_parts",
            );
            const accessories = payloadCollection.products.filter(
              (product) => product.productFamily.code === "accessories",
            );
            const spareParts = payloadCollection.products.filter(
              (product) => product.productFamily.code === "spare_parts",
            );

            collection.machineryCount = machinery.length;
            collection.accessoriesCount = accessories.length;
            collection.sparePartsCount = spareParts.length;

            collection.machinery = collectionProductMapper(
              machinery,
              numberOfProducts,
            );
            collection.accessories = collectionProductMapper(
              accessories,
              numberOfProducts,
            );
            collection.spareParts = collectionProductMapper(
              spareParts,
              numberOfProducts,
            );

            state.collections = [...state.collections, collection];
          },
        );
      }
    },
    addCollectionProductIds: (
      state,
      action: PayloadAction<{ collectionId: number; productId: number }>,
    ) => {
      state.collections = state.collections.map((collection) => {
        if (collection.id === action.payload.collectionId) {
          collection.productIds = [
            ...collection.productIds,
            action.payload.productId,
          ];
        }
        return collection;
      });
    },
    removeCollectionProductId: (
      state,
      action: PayloadAction<{ collectionId: number; productId: number }>,
    ) => {
      state.collections = state.collections.map((collection) => {
        if (collection.id === action.payload.collectionId) {
          collection.productIds = collection.productIds.filter(
            (productId) => productId !== action.payload.productId,
          );
        }
        return collection;
      });
    },
  },
});

export const collectionsApiSlice = createApi({
  reducerPath: "collectionsApi",
  baseQuery: apiBaseQuery(),
  tagTypes: [],
  endpoints: (builder) => ({
    getCollections: builder.query<CollectionPayloadInterface[], void>({
      query: () => ApiEnum.COLLECTIONS,
    }),
    createCollection: builder.mutation<
      void,
      { collectionName: string; productIds: [] }
    >({
      query: (params) => ({
        url: ApiEnum.COLLECTIONS,
        method: "POST",
        body: {
          name: params.collectionName,
          product_ids: params.productIds,
        },
      }),
    }),
    updateCollection: builder.mutation<
      void,
      { collectionId: number; collectionName: string; productIds: number[] }
    >({
      query: (params) => ({
        url: ApiEnum.COLLECTIONS + "/" + params.collectionId,
        method: "PATCH",
        body: {
          name: params.collectionName,
          product_ids: params.productIds,
        },
      }),
    }),
    deleteCollection: builder.mutation<void, number>({
      query: (collectionId) => ({
        url: ApiEnum.COLLECTIONS + "/" + collectionId,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  mapCollection,
  addCollectionProductIds,
  removeCollectionProductId,
} = collectionsSlice.actions;
export const collectionsReducer = collectionsSlice.reducer;
export const {
  useGetCollectionsQuery,
  useLazyGetCollectionsQuery,
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
  useCreateCollectionMutation,
} = collectionsApiSlice;
