import { Link } from "react-router-dom";
import { RouteEnum } from "../../enums/RouteEnum";
import { useAppDispatch, useAppLocation } from "../../../app/hooks";
import {
  menuToggle,
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";

export const Navigation = () => {
  const dispatch = useAppDispatch();
  const location = useAppLocation();

  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();

  const menuItems = [
    {
      logo: "/images/product-range.svg",
      label: StringTranslation("product-range", translations, activeLanguage),
      route: RouteEnum.PRODUCT_RANGE,
    },
    {
      logo: "/images/collections.svg",
      label: StringTranslation("collections", translations, activeLanguage),
      route: RouteEnum.COLLECTIONS,
    },
    {
      logo: "/images/grid-view-white.svg",
      label: StringTranslation("pricebook", translations, activeLanguage),
      route: RouteEnum.PRICEBOOK,
    },
    {
      logo: "/images/account.svg",
      label: StringTranslation("account", translations, activeLanguage),
      route: RouteEnum.ACCOUNT,
    },
  ];

  return (
    <nav className="l-app__menu__nav">
      <ul className="u-reset-ul">
        {menuItems.map((menuItem) => {
          let className = "";

          if (location.pathname.includes(menuItem.route)) {
            className += "isActive";
          }

          return (
            <li
              className={className}
              key={"dashboard-navigation: " + menuItem.route}
            >
              <Link to={menuItem.route} onClick={() => dispatch(menuToggle())}>
                <div className="icon">
                  <img src={menuItem.logo} alt="logo" />
                </div>
                {!translations ? (
                  <>
                    <span className="skeleton skeleton-navigation"></span>
                  </>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: menuItem.label }} />
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
