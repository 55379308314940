import { Link } from "react-router-dom";
import { RouteEnum } from "../../enums/RouteEnum";
import { ProductInterface } from "../../interfaces/ProductInterface";
import { Translate } from "../../utils/Translate";
import { useState } from "react";
import { setDeleteCollectionProductModal } from "../../../modals/deleteCollectionProductModal/DeleteCollectionProductModalSlice";
import { useAppDispatch } from "../../../app/hooks";
import { CollectionInterface } from "../../interfaces/CollectionInterface";
import { useGetUserInfoQuery } from "../../../app/rootReducer";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { TranslationsInterface } from "../../interfaces/TranslationsInterface";

interface Props {
  collection: CollectionInterface;
  category: string;
  productCount: number;
  productPages: ProductInterface[][];
}

interface ProductPageProps {
  page: [];
  index: number;
  collection: CollectionInterface;
  pageNumber: number;
}

interface ProductItemProps {
  product: ProductInterface;
  index: number;
  collection: CollectionInterface;
}

export const CollectionProducts = (props: Props) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [active, setActive] = useState(true);

  const { collection, category, productPages, productCount } = props;

  return (
    <div
      className={"c-collection__content__items" + (active ? " is-active" : "")}
    >
      <div
        className="c-collection__content__items__header"
        onClick={() => setActive(!active)}
      >
        <div className="c-collection__content__items__header__container">
          <div className="c-collection__content__items__header__title">
            <span>{category}</span>
          </div>
          <div className="c-collection__content__items__header__amount">
            {productCount}
          </div>
        </div>

        <div className="c-collection__content__items__header__icon">
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="c-collection__content__accordion">
        {productPages.map((page: [], index: number) => {
          return (
            <ProductPage
              page={page}
              index={index}
              key={"page-" + index}
              collection={collection}
              pageNumber={pageNumber}
            />
          );
        })}

        {productPages.length > 1 && (
          <div className="c-collection__content__pagination">
            <div className="c-collection__content__pagination__container">
              {productPages.map((page: [], index: number) => {
                return (
                  <div
                    className={
                      "c-collection__content__pagination__container__button" +
                      (pageNumber === index ? " is-active" : "")
                    }
                    key={page.length + index}
                    onClick={() => setPageNumber(index)}
                  >
                    <span>{index + 1}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ProductPage = (props: ProductPageProps) => {
  const { page, index, pageNumber, collection } = props;

  return (
    <div
      className={
        "c-collection__content__page" +
        (pageNumber === index ? " is-active" : "")
      }
      key={index}
    >
      {page.map((product: ProductInterface, index: number) => {
        return (
          <ProductItem
            key={index}
            product={product}
            index={index}
            collection={collection}
          />
        );
      })}
    </div>
  );
};

const ProductItem = (props: ProductItemProps) => {
  const dispatch = useAppDispatch();

  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };

  const { product, index, collection } = props;
  return (
    <div className="c-collection__content__item" key={index}>
      <div className="c-collection__content__item__left">
        <div
          className="cross"
          onClick={() => {
            dispatch(
              setDeleteCollectionProductModal({
                showPopup: true,
                productId: product.id,
                collectionId: collection.id,
              }),
            );
          }}
        >
          <img src="/images/cross.svg" alt="cross" />
        </div>
        <Link to={RouteEnum.PRODUCT_RANGE + `/${product.id}`}>
          {!!activeLanguage && (
            <span>{Translate(product.label as TranslationsInterface, activeLanguage)}</span>
          )}
        </Link>
      </div>
      <div className="c-collection__content__item__center c-collection__content__item__center--left">
        <span>{product.identifier}</span>
      </div>
      <div className="c-collection__content__item__center c-collection__content__item__center--right">
        {product.connection && <span>{product.connection}</span>}
      </div>
      {product.prices && product.prices.length > 0 && (
        <div className="c-collection__content__item__right">
          {!!activeLanguage && (
            <span>{currencyFormatter(product.prices, activeLanguage)}</span>
          )}
        </div>
      )}
    </div>
  );
};
