import type { Middleware } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";

interface RejectedAction {
  payload: {
    status: number;
  };
}

export const QueryMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const rejectedAction = action as RejectedAction;
    if (rejectedAction.payload.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    console.warn("We got a rejected action!");
  }
  return next(action);
};
