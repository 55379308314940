import { LanguageInterface } from "../interfaces/LanguageInterface";
import { TranslationsInterface } from "../interfaces/TranslationsInterface";

export const Translate = (
  texts: TranslationsInterface | undefined | null,
  activeLanguage: LanguageInterface | undefined | null,
) => {
  const activeLanguageKey = activeLanguage?.localeCode;

  if (activeLanguageKey && texts && texts[activeLanguageKey]) {
    return texts[activeLanguageKey];
  } else {
    return "";
  }
};
