import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAddCollectionProductsModal } from "./AddCollectionProductsModalSlice";
import { OptionProps } from "react-select";
import AsyncSelect from "react-select/async";
import { useLazySearchProductsQuery } from "../../pages/searchPage/SearchSlice";
import { LanguageInterface } from "../../common/interfaces/LanguageInterface";
import { useState } from "react";
import { AsyncSelectOptionInterface } from "../../common/interfaces/AsyncSelectOptionInterface";
import {
  useUpdateCollectionMutation,
  useLazyGetCollectionsQuery,
  addCollectionProductIds,
} from "../../pages/collectionsPage/CollectionsSlice";
import { MoonLoader } from "react-spinners";
import { Translate } from "../../common/utils/Translate";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { currencyFormatter } from "../../common/utils/currencyFormatter";
import { StringTranslation } from "../../common/utils/StringTranslation";
import { ProductInterface } from "../../common/interfaces/ProductInterface";
import { TranslationsInterface } from "../../common/interfaces/TranslationsInterface";

export const AddCollectionProductsModalContainer = () => {
  const dispatch = useAppDispatch();

  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();

  const { collections } = useAppSelector((state) => state.collections);
  const { collectionId } = useAppSelector(
    (state) => state.addCollectionProductsModal,
  );

  const [trigger] = useLazySearchProductsQuery();
  const [collectionTrigger] = useLazyGetCollectionsQuery();

  const [updateCollectionTrigger] = useUpdateCollectionMutation();

  const [loading, setLoading] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<
    number | ReturnType<typeof setTimeout>
  >(0);

  const paramObject = {
    collectionId: collectionId,
    collectionName:
      collections.find((item: { id: number }) => item.id === collectionId)
        ?.name || "",
    productIds:
      collections.find((item: { id: number }) => item.id === collectionId)
        ?.productIds || [],
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: AsyncSelectOptionInterface[]) => void,
  ) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (inputValue && inputValue !== "" && inputValue.length > 2) {
      setTypingTimeout(
        setTimeout(function () {
          trigger(inputValue).then((result) => {
            if (activeLanguage) {
              callback(selectOptionMapper(result.data, activeLanguage));
            }
          });
        }, 1000),
      );
    }
  };

  const IndicatorsContainer = () => {
    return (
      <div className="react-select-indicator">
        <img src="/images/magnifier.svg" alt="" />
      </div>
    );
  };

  const Option = (props: OptionProps) => {
    return (
      <label
        className={"c-products-list__sub-item__item is-active"}
        onClick={() => props.selectOption(props)}
      >
        <div className="c-products-list__sub-item__item__left">
          <div>
            <span>{props.label}</span>
          </div>
        </div>
        <div className="c-products-list__sub-item__item__center">
          <span>{(props.data as ProductInterface).identifier}</span>
        </div>
        <div className="c-products-list__sub-item__item__right">
          <span>{(props.data as ProductInterface).price}</span>
        </div>
      </label>
    );
  };

  return (
    <div className="c-modal">
      <div className="c-modal__bg" />
      <div className="c-modal__content">
        {!loading ? (
          <>
            <button
              className="c-modal__close"
              onClick={() => {
                dispatch(
                  setAddCollectionProductsModal({
                    showPopup: false,
                  }),
                );
              }}
            >
              <img
                className="c-modal__icon"
                src="/images/cross.svg"
                alt="cross"
              />
            </button>
            <header className="c-modal__header">
              <h2>
                {StringTranslation("add-product", translations, activeLanguage)}
              </h2>
            </header>
            <div className="c-modal__body">
              <p>
                {StringTranslation(
                  "do-you-want-to-add-another-product-to-this-collection",
                  translations,
                  activeLanguage,
                )}
              </p>

              <div className="c-modal__body__input">
                <div className="c-modal__body__input__label">
                  <label>
                    {StringTranslation(
                      "search-for-products",
                      translations,
                      activeLanguage,
                    )}
                  </label>
                  <span>
                    {StringTranslation(
                      "select-one-or-more-products",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                </div>

                <AsyncSelect
                  className="react-select-container"
                  isMulti={true}
                  components={{ IndicatorsContainer, Option }}
                  loadOptions={loadOptions}
                  cacheOptions
                  onChange={(selectedOptions: []) => {
                    selectedOptions.forEach(
                      (selectedOption: AsyncSelectOptionInterface) => {
                        dispatch(
                          addCollectionProductIds({
                            collectionId: collectionId,
                            productId: selectedOption.value,
                          }),
                        );
                      },
                    );
                  }}
                  styles={{
                    multiValueLabel: (styles) => ({
                      ...styles,
                      backgroundColor: "#F6F6F6",
                      color: "#000",
                      fontSize: "1.1rem",
                      fontWeight: "600",
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      backgroundColor: "#F6F6F6",
                      color: "#000",
                      cursor: "pointer",
                      transition: "all .2s ease-in-out",
                      ":hover": {
                        backgroundColor: "#E52713",
                        color: "#fff",
                      },
                    }),
                  }}
                />
              </div>

              <div className="c-modal__body__buttons">
                <button
                  className="c-btn c-btn--primary"
                  onClick={() => {
                    setLoading(true);
                    updateCollectionTrigger(paramObject).then(() => {
                      collectionTrigger().then(() => {
                        setLoading(false);
                        dispatch(
                          setAddCollectionProductsModal({
                            showPopup: false,
                          }),
                        );
                      });
                    });
                  }}
                >
                  <span>
                    +{" "}
                    {StringTranslation(
                      "add-products-to-collection",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                </button>
                <button
                  className="c-btn c-btn--secondary"
                  onClick={() => {
                    dispatch(
                      setAddCollectionProductsModal({
                        showPopup: false,
                      }),
                    );
                  }}
                >
                  <span>
                    {StringTranslation("cancel", translations, activeLanguage)}
                  </span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className={"c-loader"}>
            <MoonLoader color={"#E52713FF"} />
          </div>
        )}
      </div>
    </div>
  );
};

const selectOptionMapper = (
  data: ProductInterface[] | undefined,
  activeLanguage: LanguageInterface,
) => {
  let mappedData: AsyncSelectOptionInterface[] = [];

  if (data && data.length > 0) {
    data.forEach((item: ProductInterface) => {
      const mappedItem = {
        value: item.id,
        label: Translate(item?.label as TranslationsInterface, activeLanguage),
        price: currencyFormatter(item?.prices, activeLanguage),
        identifier: item.identifier,
      };
      mappedData = [...mappedData, mappedItem];
    });
  }
  return mappedData;
};
