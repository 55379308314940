import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setEditCollectionModal } from "./EditCollectionModalSlice";
import { useState } from "react";
import {
  useUpdateCollectionMutation,
  useLazyGetCollectionsQuery,
} from "../../pages/collectionsPage/CollectionsSlice";
import { MoonLoader } from "react-spinners";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";

export const EditCollectionModalContainer = () => {
  const dispatch = useAppDispatch();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();

  const { collections } = useAppSelector((state) => state.collections);
  const { collectionId } = useAppSelector((state) => state.editCollectionModal);

  const collectionName =
    collections.find((item: { id: number }) => item.id === collectionId)
      ?.name || "";

  const [loading, setLoading] = useState<boolean>(false);
  const [collectionNameInput, setCollectionNameInput] =
    useState<string>(collectionName);

  const [updateCollectionTrigger] = useUpdateCollectionMutation();
  const [collectionTrigger] = useLazyGetCollectionsQuery();

  const paramObject = {
    collectionId: collectionId,
    collectionName: collectionNameInput,
    productIds:
      collections.find((item: { id: number }) => item.id === collectionId)
        ?.productIds || [],
  };

  return (
    <div className="c-modal">
      <div className="c-modal__bg" />
      <div className="c-modal__content">
        {!loading ? (
          <>
            <button
              className="c-modal__close"
              onClick={() => {
                dispatch(
                  setEditCollectionModal({
                    showPopup: false,
                  }),
                );
              }}
            >
              <img
                className="c-modal__icon"
                src="/images/cross.svg"
                alt="cross"
              />
            </button>
            <header className="c-modal__header">
              <h2>
                {StringTranslation(
                  "edit-collection-name",
                  translations,
                  activeLanguage,
                )}
              </h2>
            </header>
            <div className="c-modal__body">
              <p>
                {StringTranslation(
                  "do-you-want-to-change-the-name-of-this-collection",
                  translations,
                  activeLanguage,
                )}
              </p>

              <div className="c-modal__body__input">
                <label>
                  {StringTranslation(
                    "collection-name",
                    translations,
                    activeLanguage,
                  )}
                </label>
                <input
                  type="text"
                  value={collectionNameInput}
                  onChange={(e) => setCollectionNameInput(e.target.value)}
                />
              </div>

              <div className="c-modal__body__buttons">
                <button
                  className="c-btn c-btn--primary"
                  onClick={() => {
                    setLoading(true);
                    updateCollectionTrigger(paramObject).then(() => {
                      collectionTrigger().then(() => {
                        setLoading(false);
                        dispatch(
                          setEditCollectionModal({
                            showPopup: false,
                          }),
                        );
                      });
                    });
                  }}
                >
                  <span>
                    {StringTranslation(
                      "save-collection-name",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                </button>
                <button
                  className="c-btn c-btn--secondary"
                  onClick={() => {
                    dispatch(
                      setEditCollectionModal({
                        showPopup: false,
                      }),
                    );
                  }}
                >
                  <span>
                    {StringTranslation("cancel", translations, activeLanguage)}
                  </span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className={"c-loader"}>
            <MoonLoader color={"#E52713FF"} />
          </div>
        )}
      </div>
    </div>
  );
};
