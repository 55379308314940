import { ProductInterface } from "../interfaces/ProductInterface";
import { CategoryListInterface } from "../interfaces/CategoryListInterface";
import { ProductRangeDataInterface } from "../interfaces/ProductRangeDataInterface";

export const ProductRangeSubCategoryMapper = (
  response: ProductRangeDataInterface,
  category: CategoryListInterface,
) => {
  category.children = category.children.map((child: CategoryListInterface) => {
    child.products = response.products.filter(
      (product: ProductInterface) =>
        product.categories.includes(child.id) &&
        product.prices &&
        product.prices.length > 0,
    );
    ProductRangeSubCategoryMapper(response, child);
    return child;
  });

  return category;
};
