import { LanguageInterface } from "../interfaces/LanguageInterface";
import { Translate } from "./Translate";
import { TranslationsInterface } from "../interfaces/TranslationsInterface";

export const StringTranslation = (
  key: string,
  texts: TranslationsInterface | undefined | null,
  activeLanguage: LanguageInterface | undefined | null,
) => {
  if (activeLanguage && texts && texts[key]) {
    return Translate(
      texts[key] as unknown as TranslationsInterface,
      activeLanguage,
    );
  } else {
    return "Missing translations for key: " + key;
  }
};
