interface Props {
  color: string;
}

export const SvgDownload = (props: Props) => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 13.1 13.1"
    >
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter"
          filterUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="13.1"
          height="13.1"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="13.1"
        height="13.1"
        id="mask0_246_8114_00000132079821765668425940000017695563078031167925_"
      >
        <rect
          x="-3.3"
          y="-3.3"
          className="st0"
          fill={props.color}
          width="19.6"
          height="19.6"
        />
      </mask>
      <g className="st1" fill={props.color}>
        <path
          className="st2"
          fill={props.color}
          d="M1.6,13.1c-0.4,0-0.8-0.2-1.2-0.5C0.2,12.3,0,11.9,0,11.4V9h1.6v2.5h9.8V9h1.6v2.5c0,0.4-0.2,0.8-0.5,1.2
                    c-0.3,0.3-0.7,0.5-1.2,0.5H1.6z M6.5,9.8L2.5,5.7l1.1-1.2l2.1,2.1V0h1.6v6.7l2.1-2.1l1.1,1.2L6.5,9.8z"
        />
      </g>
    </svg>
  );
};
