import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setAddCollectionModal,
  mapCollection,
  setSelectedCollection,
} from "./AddCollectionModalSlice";
import { clearSelectedProducts } from "../../pages/productRangePage/ProductRangeSlice";
import { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import Select from "react-select";
import {
  useCreateCollectionMutation,
  useGetCollectionsQuery,
  useLazyGetCollectionsQuery,
  useUpdateCollectionMutation,
} from "../../pages/collectionsPage/CollectionsSlice";
import { RouteEnum } from "../../common/enums/RouteEnum";
import { useNavigate } from "react-router-dom";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";

export const AddCollectionModalContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data } = useGetCollectionsQuery();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();

  const [collectionTrigger] = useLazyGetCollectionsQuery();
  const [createCollectionTrigger] = useCreateCollectionMutation();
  const [updateCollectionTrigger] = useUpdateCollectionMutation();

  const { collections } = useAppSelector((state) => state.collections);
  const { selectedProductIds, collectionId, collectionOptions } =
    useAppSelector((state) => state.addCollectionModal);

  const [collectionType, setCollectionType] = useState<string>("new");

  const [loading, setLoading] = useState<boolean>(false);
  const [collectionName, setCollectionName] = useState<string>("");

  const addCollectionSubmitHandler = () => {
    setLoading(true);
    if (collectionType === "new") {
      const paramObject = {
        collectionName: collectionName,
        productIds: selectedProductIds,
      };

      createCollectionTrigger(paramObject).then(() => {
        collectionTrigger().then(() => {
          setLoading(false);
          dispatch(
            setAddCollectionModal({
              showPopup: false,
            }),
          );
        });
      });
    } else {
      const paramObject = {
        collectionId: collectionId,
        collectionName:
          collections.find((item: { id: number }) => item.id === collectionId)
            ?.name || "",
        productIds: [
          ...(collections.find(
            (item: { id: number }) => item.id === collectionId,
          )?.productIds || []),
          ...selectedProductIds,
        ],
      };

      updateCollectionTrigger(paramObject).then(() => {
        collectionTrigger().then(() => {
          setLoading(false);
          dispatch(
            setAddCollectionModal({
              showPopup: false,
            }),
          );
        });
      });
    }
    dispatch(clearSelectedProducts());
    navigate(RouteEnum.COLLECTIONS);
  };

  useEffect(() => {
    if (data) {
      dispatch(mapCollection(data));
    }
  }, [dispatch, data]);

  return (
    <div className="c-modal">
      <div className="c-modal__bg" />
      <div className="c-modal__content">
        {!loading ? (
          <>
            <button
              className="c-modal__close"
              onClick={() => {
                dispatch(
                  setAddCollectionModal({
                    showPopup: false,
                  }),
                );
              }}
            >
              <img
                className="c-modal__icon"
                src="/images/cross.svg"
                alt="cross"
              />
            </button>
            <header className="c-modal__header">
              <h2>
                {StringTranslation(
                  "how-do-you-want-to-save-this-selection",
                  translations,
                  activeLanguage,
                )}
              </h2>
            </header>
            <div className="c-modal__body">
              <p>
                {StringTranslation(
                  "add-the-selection-to-an",
                  translations,
                  activeLanguage,
                )}
              </p>

              <div className="c-modal__body__radio-input">
                <div
                  className="c-modal__body__radio-input__group"
                  onClick={() => {
                    setCollectionType("new");
                  }}
                >
                  <input
                    type="radio"
                    id="new"
                    name="collection-type"
                    readOnly={true}
                    checked={collectionType === "new"}
                  />
                  <label>
                    {StringTranslation(
                      "new-collection",
                      translations,
                      activeLanguage,
                    )}
                  </label>
                </div>
                <div
                  className="c-modal__body__radio-input__group"
                  onClick={() => {
                    setCollectionType("existing");
                  }}
                >
                  <input
                    type="radio"
                    id="existing"
                    name="collection-type"
                    readOnly={true}
                    checked={collectionType === "existing"}
                  />
                  <label>
                    {StringTranslation(
                      "existing-collection",
                      translations,
                      activeLanguage,
                    )}
                  </label>
                </div>
              </div>

              {collectionType === "new" ? (
                <div className="c-modal__body__input">
                  <label>
                    {StringTranslation(
                      "collection-name",
                      translations,
                      activeLanguage,
                    )}
                  </label>
                  <input
                    type="text"
                    value={collectionName}
                    onChange={(e) => setCollectionName(e.target.value)}
                  />
                </div>
              ) : (
                <div className="c-modal__body__input">
                  {!!collectionOptions && (
                    <>
                      <label>Collection name</label>
                      <Select
                        className="collection-select"
                        classNamePrefix="select"
                        name="collections"
                        isLoading={false}
                        isClearable={false}
                        isSearchable={true}
                        options={collectionOptions}
                        onChange={(selectedOption: { value: string }) => {
                          dispatch(
                            setSelectedCollection(Number(selectedOption.value)),
                          );
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "4.5rem",
                            minHeight: "4.5rem",
                          }),
                          input: (provided) => ({
                            ...provided,
                            margin: "0px",
                            height: "4.5rem",
                            minHeight: "4.5rem",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            height: "4.5rem",
                            minHeight: "4.5rem",
                            padding: "0 1rem",
                          }),
                          indicatorsContainer: (provided) => ({
                            ...provided,
                            height: "4.5rem",
                            minHeight: "4.5rem",
                          }),
                        }}
                      />
                    </>
                  )}
                </div>
              )}

              <div className="c-modal__body__buttons">
                <button
                  className="c-btn c-btn--primary"
                  onClick={() => {
                    addCollectionSubmitHandler();
                  }}
                >
                  <span>
                    {StringTranslation(
                      "save-collection",
                      translations,
                      activeLanguage,
                    )}
                  </span>
                </button>
                <button
                  className="c-btn c-btn--secondary"
                  onClick={() => {
                    dispatch(
                      setAddCollectionModal({
                        showPopup: false,
                      }),
                    );
                  }}
                >
                  <span>
                    {StringTranslation("cancel", translations, activeLanguage)}
                  </span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className={"c-loader"}>
            <MoonLoader color={"#E52713FF"} />
          </div>
        )}
      </div>
    </div>
  );
};
