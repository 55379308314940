import { StringTranslation } from "../../utils/StringTranslation";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { ProductInterface } from "../../interfaces/ProductInterface";
import { Translate } from "../../utils/Translate";
import { RouteEnum } from "../../enums/RouteEnum";
import { Link } from "react-router-dom";
import { TranslationsInterface } from "../../interfaces/TranslationsInterface";

interface Props {
  product: ProductInterface;
}

export const ProductRelatedProductsItem = (props: Props) => {
  const { data: translations } = useGetTranslationsQuery();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { product } = props;

  return (
    <div className="c-related-products__item">
      {product.image && (
        <div className="c-related-products__item__top">
          <div className="carousel-product-image">
            <img src={product.image} alt="thumbnail" />
          </div>
        </div>
      )}
      <div className="c-related-products__item__bottom">
        {!!activeLanguage && (
          <div className="footer-top">
            <span>
              {Translate(
                product?.label as TranslationsInterface,
                activeLanguage,
              )}
            </span>
          </div>
        )}
        <div className="button-container">
          <div className="c-btn c-btn--primary btn-view-product">
            <Link to={RouteEnum.PRODUCT_RANGE + `/${product.id}`}>
              {StringTranslation("view-product", translations, activeLanguage)}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
