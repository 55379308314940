import { useGetProductDetailsQuery } from "./ProductDetailsSlice";
import { Link, useParams } from "react-router-dom";
import { ProductCarousel } from "../../common/components/productDetail/ProductCarousel";
import { ProductInfoBar } from "../../common/components/productDetail/ProductInfoBar";
import { ProductGeneralInformation } from "../../common/components/productDetail/ProductGeneralInformation";
import { ProductSpecifications } from "../../common/components/productDetail/ProductSpecifications";
import { ProductDownloads } from "../../common/components/productDetail/ProductDownloads";
import {
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../app/rootReducer";
import { StringTranslation } from "../../common/utils/StringTranslation";
import { Translate } from "../../common/utils/Translate";
import { RouteEnum } from "../../common/enums/RouteEnum";
import {
  setProductType,
  useGetProductRangeQuery,
} from "../productRangePage/ProductRangeSlice";
import { ProductTypeEnum } from "../../common/enums/ProductTypeEnum";
import { useAppDispatch } from "../../app/hooks";
import { ProductOlderModels } from "../../common/components/productDetail/ProductOlderModels";
import { ProductRelatedProducts } from "../../common/components/productDetail/ProductRelatedProducts";
import { TranslationsInterface } from "../../common/interfaces/TranslationsInterface";

const ProductDetailsPageContainer = () => {
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId: string }>();
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };

  const { data: accessories, isLoading: isAccessoriesLoading } =
    useGetProductRangeQuery({
      type: ProductTypeEnum.accessories,
      filters: [],
      includeArchived: true,
    });

  const { data: products, isLoading: isProductsLoading } =
    useGetProductRangeQuery({
      type: ProductTypeEnum.products,
      filters: [],
      includeArchived: true,
    });

  const { data, isLoading } = useGetProductDetailsQuery(
    {
      productId: productId ?? "",
      products: products,
      accessories: accessories,
      language: activeLanguage,
    },
    {
      skip: isAccessoriesLoading || isProductsLoading,
    },
  );

  const { data: translations, isLoading: isTranslationsLoading } =
    useGetTranslationsQuery();
  const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

  const renderBreadcrumbs = () => {
    if (!data || !translationsLoaded || isLoading) {
      return (
        <>
          <span className="skeleton skeleton-breadcrumb"></span>
          <div className="c-breadcrumbs__arrow">
            <img src="/images/arrow-black.svg" alt="arrow" />
          </div>
          <span className="skeleton skeleton-breadcrumb"></span>
          <div className="c-breadcrumbs__arrow">
            <img src="/images/arrow-black.svg" alt="arrow" />
          </div>
          <span className="skeleton skeleton-breadcrumb"></span>
        </>
      );
    }

    return (
      <>
        <Link to={RouteEnum.PRODUCT_RANGE}>
          <span>
            {StringTranslation("product-range", translations, activeLanguage)}
          </span>
        </Link>
        <div className="c-breadcrumbs__arrow">
          <img src="/images/arrow-black.svg" alt="arrow" />
        </div>
        {data.productFamily?.code === "accessories" ? (
          <Link
            to={RouteEnum.PRODUCT_RANGE}
            onClick={() =>
              dispatch(setProductType(ProductTypeEnum.accessories))
            }
          >
            <span>
              {StringTranslation("accessories", translations, activeLanguage)}
            </span>
          </Link>
        ) : (
          <Link
            to={RouteEnum.PRODUCT_RANGE}
            onClick={() => dispatch(setProductType(ProductTypeEnum.products))}
          >
            <span>
              {StringTranslation("machines", translations, activeLanguage)}
            </span>
          </Link>
        )}
        <div className="c-breadcrumbs__arrow">
          <img src="/images/arrow-black.svg" alt="arrow" />
        </div>
        <span>
          {Translate(
            data.label as unknown as TranslationsInterface,
            activeLanguage,
          )}
        </span>
      </>
    );
  };

  const renderInfoBar = () => {
    if (!data || !activeLanguage || !translations || isLoading) {
      return (
        <div className="c-product-info-bar">
          <div className="c-product-info-bar__info">
            <div className="c-product-info-bar__info__left">
              <div className="c-product-info-bar__info__name">
                <span className="skeleton skeleton-title-large"></span>
              </div>
              <div className="c-product-info-bar__info__item c-product-info-bar__info__item__price">
                <span className="skeleton skeleton-name"></span>
                <span className="skeleton skeleton-name"></span>
              </div>
              <div className="c-product-info-bar__info__item">
                <span className="skeleton skeleton-name"></span>
                <span className="skeleton skeleton-name"></span>
              </div>
              <div className="c-product-info-bar__info__item">
                <span className="skeleton skeleton-name"></span>
              </div>
            </div>
          </div>
          <div className="c-product-info-bar__breadcrumbs">
            <div className="c-breadcrumbs">
              <div className="skeleton skeleton-breadcrumb-slash"></div>
              <span>/</span>
              <div className="skeleton skeleton-breadcrumb-slash"></div>
              <span>/</span>
              <div className="skeleton skeleton-breadcrumb-slash"></div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <ProductInfoBar
        data={data}
        translations={translations}
        activeLanguage={activeLanguage}
      />
    );
  };

  return (
    <div className="p-product-details">
      <div className="c-breadcrumbs">{renderBreadcrumbs()}</div>

      <ProductCarousel data={data} />

      {renderInfoBar()}

      <div className="p-product-details__specification-info">
        {translationsLoaded && translations && data && !isLoading ? (
          <ProductGeneralInformation
            data={data}
            translations={translations}
            activeLanguage={activeLanguage}
          />
        ) : (
          <div className="c-product-general-info">
            <h2 className="skeleton skeleton-title-large" />
            <div className="skeleton skeleton-text-large" />
            <h3 className="skeleton skeleton-title" />
            <ul className="skeleton-list">
              {Array.from(Array(15)).map((_, index) => (
                <li key={index} className="skeleton skeleton-text-small"></li>
              ))}
            </ul>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-text-small"></div>
          </div>
        )}

        <ProductSpecifications data={data} activeLanguage={activeLanguage} />
      </div>

      <div className="p-product-details__product-sliders">
        <ProductRelatedProducts
          products={data?.productAssociations?.up_sell}
          title={StringTranslation("accessories", translations, activeLanguage)}
        />

        <ProductRelatedProducts
          products={data?.productAssociations?.previous_model}
          title={StringTranslation(
            "similar-machines",
            translations,
            activeLanguage,
          )}
        />
      </div>

      {!isLoading && !!data && !!activeLanguage && (
        <div className="p-product-details__downloads">
          <ProductDownloads
            data={data}
            title={StringTranslation(
              "marketing-downloads",
              translations,
              activeLanguage,
            )}
            activeLanguage={activeLanguage}
            type={"marketing"}
          />
          <ProductDownloads
            data={data}
            title={StringTranslation(
              "technical-downloads",
              translations,
              activeLanguage,
            )}
            activeLanguage={activeLanguage}
            type={"technical"}
          />
        </div>
      )}

      <div className="p-product-details__older-models">
        {translationsLoaded &&
          data &&
          data.olderModelAssociations &&
          data.olderModelAssociations.length > 0 && (
            <ProductOlderModels
              data={data}
              title={StringTranslation(
                "older-models",
                translations,
                activeLanguage,
              )}
              activeLanguage={activeLanguage}
            />
          )}
      </div>
    </div>
  );
};
export default ProductDetailsPageContainer;
