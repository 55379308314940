export function convertUnitToAbbreviation(unit: string): string {
  switch (unit.toLowerCase()) {
    case "millimeter":
      return "mm";
    case "centimeter":
      return "cm";
    case "meter":
      return "m";
    case "kilometer":
      return "km";
    case "gram":
      return "g";
    case "kilogram":
      return "kg";
    case "liter":
      return "l";
    default:
      return unit.toLowerCase();
  }
}
