import { SvgDownload } from "../svg/SvgDownload";
import { PriceBookInterface } from "../../interfaces/PriceBookInterface";
import { LanguageInterface } from "../../interfaces/LanguageInterface";
import { useGetTranslationsQuery } from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";

interface Props {
  data: PriceBookInterface;
  activeLanguage: LanguageInterface | null;
}

export const DownloadItem = (props: Props) => {
  const { data } = props;
  const { activeLanguage } = props;
  const { data: translations } = useGetTranslationsQuery();

  return (
    <a
      href={data.fileUrl}
      target="_blank"
      rel="noreferrer"
      className="c-download-item"
    >
      <div className="c-download-item__left">
        <span>{data.fileName}</span>
      </div>
      <span className="c-download-item__middle">{data.fileType}</span>
      <span className="c-download-item__right">
        <div>
          <div className="c-download-item__download-icon">
            <SvgDownload color={"#151112"} />
          </div>
          {StringTranslation("download", translations, activeLanguage)}
        </div>
      </span>
    </a>
  );
};
