import { LanguageInterface } from "../interfaces/LanguageInterface";

export const currencyFormatter = (
  data: {
    locale: string;
    amount: number;
    currency: string;
  }[],
  activeLanguage: LanguageInterface,
) => {
  const price = data.filter(
    (price) => price.locale === activeLanguage.localeCode,
  )[0];

  const locale = price?.locale.replace("_", "-");
  let formattedPrice = "";
  if (price) {
    if (locale === "en-AU") {
      // if the locale is international (en-AU), we need to format the price as nl-NL BRA6-576
      formattedPrice = Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: price.currency,
        useGrouping: true,
      }).format(price?.amount);

      return formattedPrice;
    }

    formattedPrice = Intl.NumberFormat(locale, {
      style: "currency",
      currency: price.currency,
      useGrouping: true,
    }).format(price?.amount)
  } else {
    formattedPrice = "";
  }

  return formattedPrice;
};
