import { useState } from "react";
import { FilterListInterface } from "../../interfaces/FilterListInterface";
import { Translate } from "../../utils/Translate";
import { selectFilter } from "../../../pages/productRangePage/ProductRangeSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useGetUserInfoQuery } from "../../../app/rootReducer";
import { Tooltip } from "./Tooltip";
import { TranslationsInterface } from "../../interfaces/TranslationsInterface";

interface Props {
  filter: FilterListInterface;
}

interface Option {
  id: string;
  label: string;
  labels: object;
  value: string | number;
}

export const ProductFilter = (props: Props) => {
  const dispatch = useAppDispatch();

  const [active, setActive] = useState(true);

  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };

  const { selectedFilters } = useAppSelector((state) => state.productRange);
  const { filter } = props;

  if (filter) {
    return (
      <div
        className={"c-product-filters__filter " + (active ? "is-active" : "")}
      >
        <div
          className="c-product-filters__filter__header"
          onClick={() => setActive(!active)}
        >
          <div className="c-product-filters__filter__header__title">
            {!!activeLanguage && (
              <span>
                {Translate(
                  filter.label as TranslationsInterface,
                  activeLanguage,
                )}
              </span>
            )}
            {!!filter.filterTooltip &&
              activeLanguage &&
              !!Object.getOwnPropertyDescriptor(
                filter.filterTooltip,
                activeLanguage.localeCode,
              ) && (
                <Tooltip
                  text={Translate(
                    filter.filterTooltip as TranslationsInterface,
                    activeLanguage,
                  )}
                />
              )}
          </div>
          <div className="c-product-filters__filter__header__icon">
            <span></span>
            <span></span>
          </div>
        </div>
        {!!filter.options && (
          <div className="c-product-filters__filter__options">
            {Object.values(filter.options).map(
              (option: Option, index: number) => {

                return (
                  <label
                    key={index}
                    className="c-product-filters__filter__option"
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={
                        !!selectedFilters[filter.id]?.options.includes(
                          option.id,
                        )
                      }
                      onChange={() =>
                        dispatch(selectFilter({ filter, option }))
                      }
                    />
                    {!!activeLanguage && (
                      <span>
                        {Translate(
                          option.labels as TranslationsInterface,
                          activeLanguage,
                        )}
                      </span>
                    )}
                  </label>
                );
              },
            )}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export const ProductFilterSkeleton = () => {
  return (
    <div className="c-product-filters__filter is-active">
      <div className="c-product-filters__filter__header">
        <div className="c-product-filters__filter__header__title">
          <span className="skeleton skeleton-name"></span>
        </div>
        <div className="c-product-filters__filter__header__icon">
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="c-product-filters__filter__options">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <label
              key={index}
              className="skeleton-input c-product-filters__filter__option"
            >
              <input type="checkbox" className="checkbox" />
              <span className="skeleton skeleton-input-option"></span>
            </label>
          ))}
      </div>
    </div>
  );
};
