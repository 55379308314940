import { useEffect, useRef, useState } from "react";
import {
  setShowIntroduction,
  useGetTranslationsQuery,
  useGetUserInfoQuery,
} from "../../../app/rootReducer";
import { StringTranslation } from "../../utils/StringTranslation";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";

export const Introduction = () => {
  const userData = useGetUserInfoQuery();
  const { activeLanguage } = userData?.data || { activeLanguage: null };
  const { data: translations } = useGetTranslationsQuery();
  const storyRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const { showIntroduction } = useAppSelector((state) => state.root);
  const dispatch = useDispatch();

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const storyElement = storyRef.current;
    const imageElement = imageRef.current;
    if (storyElement && imageElement) {
      setTimeout(() => {
        storyElement.style.opacity = "1";
        imageElement.style.opacity = "1";
      }, 100);
    }
  }, [currentSlide, showIntroduction]);

  const slides = [
    {
      image: "/images/slides/slide1.png",
      imageText: StringTranslation(
        "introduction-slide-one-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-one-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-one-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-one-description",
        translations,
        activeLanguage,
      ),
    },
    {
      image: "/images/slides/slide2.png",
      imageText: StringTranslation(
        "introduction-slide-two-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-two-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-two-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-two-description",
        translations,
        activeLanguage,
      ),
    },
    {
      image: "/images/slides/slide3.png",
      imageText: StringTranslation(
        "introduction-slide-three-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-three-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-three-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-three-description",
        translations,
        activeLanguage,
      ),
    },
    {
      image: "/images/slides/slide4.png",
      imageText: StringTranslation(
        "introduction-slide-four-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-four-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-four-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-four-description",
        translations,
        activeLanguage,
      ),
    },
    {
      image: "/images/slides/slide5.png",
      imageText: StringTranslation(
        "introduction-slide-five-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-five-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-five-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-five-description",
        translations,
        activeLanguage,
      ),
    },
    // {
    //     image: "/images/slides/slide6.png",
    //     imageText: StringTranslation('introduction-slide-six-image-text', translations, activeLanguage),
    //     title: StringTranslation('introduction-slide-six-title', translations, activeLanguage),
    //     subtitle: StringTranslation('introduction-slide-six-subtitle', translations, activeLanguage),
    //     description: StringTranslation('introduction-slide-six-description', translations, activeLanguage),
    // },
    {
      image: "/images/slides/slide7.png",
      imageText: StringTranslation(
        "introduction-slide-seven-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-seven-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-seven-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-seven-description",
        translations,
        activeLanguage,
      ),
    },
    {
      image: "/images/slides/slide8.png",
      imageText: StringTranslation(
        "introduction-slide-eight-image-text",
        translations,
        activeLanguage,
      ),
      title: StringTranslation(
        "introduction-slide-eight-title",
        translations,
        activeLanguage,
      ),
      subtitle: StringTranslation(
        "introduction-slide-eight-subtitle",
        translations,
        activeLanguage,
      ),
      description: StringTranslation(
        "introduction-slide-eight-description",
        translations,
        activeLanguage,
      ),
    },
  ];

  const handleClick = () => {
    localStorage.setItem("introductionSlide", "false");
    dispatch(setShowIntroduction(false));
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length,
    );
  };

  if (!showIntroduction) return null;

  return (
    <div className="l-dark-overlay">
      <div className="l-dark-overlay__container">
        <section className="c-introduction">
          <div className="c-introduction__left">
            <div
              key={currentSlide}
              ref={imageRef}
              className={"c-introduction__image-container"}
            >
              <div className={"c-introduction__left__container"}>
                <img
                  className="c-introduction__image"
                  src={slides[currentSlide].image}
                  alt="Intro"
                />
              </div>
              <div className="c-introduction__image-container__title">
                <p className="c-introduction__image-container__title__number">
                  {currentSlide + 1}
                </p>
                <p className="c-introduction__image-container__title__text">
                  {slides[currentSlide].imageText}
                </p>
              </div>
            </div>
          </div>
          <div className="c-introduction__right">
            <button className="c-introduction__close" onClick={handleClick}>
              <img src={"/images/cross.svg"} alt="cross" />
            </button>
            <div
              key={currentSlide}
              ref={storyRef}
              className="c-introduction__story"
            >
              <h2 className="c-introduction__story__title">
                {slides[currentSlide].title}
              </h2>
              <p className="c-introduction__story__subtitle">
                {slides[currentSlide].subtitle}
              </p>
              <p className="c-introduction__story__text">
                {slides[currentSlide].description}
              </p>
            </div>
            <div className="c-introduction__buttons">
              <div className="c-introduction__arrows">
                <button
                  className="c-introduction__arrow c-introduction__arrow--left"
                  onClick={handlePrev}
                >
                  <img src="/images/arrow-down.svg" alt="arrow-left" />
                </button>
                <button
                  className="c-introduction__arrow c-introduction__arrow--right"
                  onClick={handleNext}
                >
                  <img src="/images/arrow-down.svg" alt="arrow-right" />
                </button>
              </div>
              <button
                className="c-introduction__button c-introduction__button--skip-part"
                onClick={handleClick}
              >
                {StringTranslation(
                  "introduction-skip-button",
                  translations,
                  activeLanguage,
                )}
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
